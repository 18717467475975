import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import storage from 'localforage';
import { persistReducer } from 'redux-persist';

const initialState = ImmutableMap({
  items: ImmutableList(),
  isLoading: false,
  ready: false,
});


const persistConfig = {
  key: 'root',
  storage,
};

// eslint-disable-next-line no-unused-vars
function instancesReducer(state = initialState, action) {
  return state;
}


export default persistReducer(persistConfig, instancesReducer);
