import Storage from './storage';
import { APP_PREFERENCES } from './constants';

const lightStyleRegExp = /(<link href="\/eunomia\/static\/css\/eunomia-light-)(.*)(rel="stylesheet">)/;
const lightScriptRegExp = /(<script src="\/eunomia\/static\/js\/eunomia-light-)(.*)(<\/script>)/;
const darkStyleRegExp = /(<!--)(<link href="\/eunomia\/static\/css\/eunomia-light-)(.*)(rel="stylesheet">)(-->)/;
const darkScriptRegExp = /(<!--)(<script src="\/eunomia\/static\/js\/eunomia-light-)(.*)(<\/script>)(-->)/;

export const getTheme = () => {
  const headContent = document.head.innerHTML;
  if (darkStyleRegExp.test(headContent)) {
    return 'dark';
  }
  return 'light';
};

export const saveTheme = (name, cb = null) => {
  let preferences = { theme: name };
  Storage.get(APP_PREFERENCES).then((_preferences) => {
    if (_preferences) {
      preferences = _preferences;
      preferences.theme = name;
    }
    Storage.set(APP_PREFERENCES, preferences).then(() => {
      if (cb) {
        cb();
      }
    }).catch(console.warn);
  }).catch(console.warn);
};

export const setTheme = (name, save = true, cb = null) => {
  if (name !== 'light' && name !== 'dark') {
    return;
  }
  const headContent = document.head.innerHTML;
  if (name === 'light' && darkStyleRegExp.exec(headContent)) {
    const replacement = '$2$3$4';
    document.head.innerHTML = headContent.replace(darkStyleRegExp, replacement).replace(darkScriptRegExp, replacement);
  } else if (lightStyleRegExp.exec(headContent)){
    const replacement = '<!--$1$2$3-->';
    document.head.innerHTML = headContent.replace(lightStyleRegExp, replacement).replace(lightScriptRegExp, replacement);
  }
  if (save) {
    saveTheme(name, cb);
  } else {
    if (cb) {
      cb();
    }
  }
};


export const toggleTheme = (save = true, replace = true, then = null) => {
  let newTheme = 'dark';
  Storage.get(APP_PREFERENCES).then((preferences) => {
    if (preferences && preferences.theme) {
      const { theme } = preferences;
      if (theme === 'dark') {
        newTheme = 'light';
      }
      if (!replace) {
        saveTheme(newTheme, then);
      } else {
        setTheme(newTheme, save, then);
      }
    } else {
      setTheme(newTheme, save, then);
    }
  }).catch(console.warn);
};
