import './public-path';
import loadPolyfills from '../src/load_polyfills';
import { start } from '../src/common';

start();

loadPolyfills().then(() => {

  require('../src/main').default();
}).catch(e => {
  console.error(e);
});
