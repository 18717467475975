import { supportsPassiveEvents } from 'detect-passive-events';
import { forceSingleColumn } from './initial_state';

const iOSAppUserAgent = 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0.3 Safari/605.1.15';
const LAYOUT_BREAKPOINT = 630;

export const isMobile = width => width <= LAYOUT_BREAKPOINT;

export const layoutFromWindow = () => {
  if (isMobile(window.innerWidth)) {
    return 'mobile';
  } else if (forceSingleColumn) {
    return 'single-column';
  } else {
    return 'multi-column';
  }
};

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

let userTouching = false;
let listenerOptions = supportsPassiveEvents ? { passive: true } : false;

const touchListener = () => {
  userTouching = true;
  window.removeEventListener('touchstart', touchListener, listenerOptions);
};

window.addEventListener('touchstart', touchListener, listenerOptions);

export const isUserTouching = () => userTouching;

export const isIOS = () => iOS;

export const isMobileIOS = () => isMobile(window.innerWidth) && navigator.userAgent === iOSAppUserAgent;
