import { Map as ImmutableMap, fromJS } from 'immutable';
import {
  EUNOMIA_POST_IMPORT,
  EUNOMIA_POSTS_IMPORT,
  EUNOMIA_VOTING_INIT,
  EUNOMIA_CASCADE_IMPORT,
  EUNOMIA_CASCADE_OPEN,
} from '../constants';
import { COMPOSE_REPLY } from '../../../actions/compose';

const initialState = ImmutableMap({
  cascadeOpen: null,
  votingInited: false,
});

const normalizePost = (state, post) => {
  const itemId = post ? post.source_id: null;
  if (itemId) {
    const existing = state.get(itemId, null);
    if (existing && existing.get('validated', false) === true && existing.get('processed', 'no') !== 'yes') {
      post.validated = true;
    }
    return state.set(itemId, fromJS(post));
  } else {
    return state;
  }
};

const normalizePosts = (state, posts) => {
  posts.forEach((post) => {
    state = normalizePost(state, post);
  });
  return state;
};

const importCascadePosts = (state, payload) => {
  const { cascadeId, posts } = payload;
  if (cascadeId && posts) {
    posts.forEach((post) => {
      state = normalizePost(state, post);
    });
  }
  return state;
};


export default function eunomiaPosts(state = initialState, action) {
  switch(action.type) {
  case EUNOMIA_VOTING_INIT:
    return state.set('votingInited', action.value);
  case EUNOMIA_POST_IMPORT:
    return normalizePost(state, action.post);
  case EUNOMIA_CASCADE_IMPORT:
    return importCascadePosts(state, action.payload);
  case EUNOMIA_POSTS_IMPORT:
    return normalizePosts(state, action.posts);
  case EUNOMIA_CASCADE_OPEN:
    return state.set('cascadeOpen', action.open);
  case COMPOSE_REPLY:
    return state.set('cascadeOpen', null);
  default:
    return state;
  }
}
