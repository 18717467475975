import { plainDomain, db } from '../features/eunomia';
import axios from 'axios';

let initialState;
let reduceMotion,
  streaming_api_base_url,
  autoPlayGif,
  displayMedia,
  expandSpoilers,
  unfollowModal,
  boostModal,
  deleteModal,
  me,
  myId,
  myUrl,
  version,
  invitesEnabled,
  profile_directory,
  useBlurhash,
  usePendingItems,
  showTrends,
  cropImages,
  disableSwiping,
  domain,
  domainName,
  accessToken,
  applicationServerKey,
  eunomiaDomain,
  isStaff,
  mascot,
  repository,
  source_url;

const forceSingleColumn = true;
const title = 'EUNOMIA Digital Companion';
const searchEnabled = true;

const getMeta = (prop) => {
  return initialState && initialState.meta && initialState.meta[prop];
};

export const getMetaFromApi = (token, baseUri) => {
  return new Promise((resolve, reject) => {
    if (!baseUri || !token) {
      reject({ 'reason': 'Invalid input' });
    }
    const domain = plainDomain(baseUri);
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` };
    const preferencesUrl = `https://${domain}/api/v1/preferences`;
    const instanceUrl = `https://${domain}/api/v1/instance`;
    const accountUrl = `https://${domain}/api/v1/accounts/verify_credentials`;
    const preferencesRequest = axios.get(preferencesUrl, { headers });
    const instanceRequest = axios.get(instanceUrl, { headers });
    const accountRequest = axios.get(accountUrl, { headers });
    let pushConfig = {
      id: '',
      endpoint: '',
      alerts: {
        follow: true,
        follow_request: true,
        favourite: true,
        mention: true,
        poll: true,
        status: true,
      },
    };
    db.get('SAVED_PUSH_CONFIG').then((storedConfig) => {
      if (storedConfig) {
        pushConfig = storedConfig;
      }
    }).catch().finally(() => {
      axios.all([preferencesRequest, instanceRequest, accountRequest])
        .then(axios.spread((preferencesResponse, instanceResponse, accountResponse) => {
          const preferences = preferencesResponse.data;
          const instance = instanceResponse.data;
          instance.push_subscription = pushConfig;
          const account = accountResponse.data;
          resolve({ preferences, instance, account });
        })).catch((reason) => {
          reject(reason);
        });
    });
  });
};

export const setInitialState = (instance, preferences, account, access_token, appServerKey, eunomia) => {
  const accounts = {};
  accounts[account.id] = account;
  if (instance.contact_account && instance.contact_account.id !== account.id) {
    accounts[instance.contact_account.id] = instance.contact_account;
  }
  let pushConfig = instance.push_subscription;
  if (pushConfig && !pushConfig.server_key) {
    pushConfig.server_key = appServerKey;
  }
  const push_subscription = pushConfig ?? {
    id: '',
    endpoint: '',
    alerts: {
      follow: true,
      follow_request: true,
      favourite: true,
      mention: true,
      poll: true,
      status: true,
    },
    server_key: appServerKey,
  };
  applicationServerKey = appServerKey;
  const meta = {
    streaming_api_base_url: instance.urls ? instance.urls.streaming_api ?? `wss:${instance.uri}` : `wss:${instance.uri}`,
    domain: instance.uri,
    domainName: instance.uri,
    access_token,
    search_enabled: true,
    invites_enabled: instance.invites_enabled ?? false,
    me: account.id,
    myId: `${account.id}@mastodon`,
    myUrl: account.url,
    display_media: preferences['reading:expand:media'] ?? 'default',
    expand_spoilers:preferences['reading:expand:spoilers'] ?? false,
    version: instance.version ?? '1.0.0',
    // not returned from api, can be modified (locally) later on user settings
    profile_directory:true,
    trends:true,
    unfollow_modal:false,
    boost_modal:false,
    delete_modal:true,
    auto_play_gif:false,
    reduce_motion:false,
    disable_swiping:false,
    use_blurhash:true,
    use_pending_items:false,
    crop_images:true,
    eunomiaDomain: plainDomain(Object.keys(eunomia)[0]),
  };
  const newInitialState = {
    meta,
    compose:{
      me: account.id,
      default_privacy: preferences['posting:default:visibility'] ?? 'public',
      default_sensitive: preferences['posting:default:sensitive'] ?? false,
      text:'',
    },
    accounts,
    push_subscription,
  };
  _setInitialState(newInitialState);
};

export const getInitialState = () => initialState;
export const _setInitialState = (content) => {
  initialState = content;
  reduceMotion = getMeta('reduce_motion');
  streaming_api_base_url = getMeta('streaming_api_base_url');
  autoPlayGif = getMeta('auto_play_gif');
  displayMedia = getMeta('display_media');
  expandSpoilers = getMeta('expand_spoilers');
  unfollowModal = getMeta('unfollow_modal');
  boostModal = getMeta('boost_modal');
  deleteModal = getMeta('delete_modal');
  me = getMeta('me');
  myId = getMeta('myId');
  myUrl = getMeta('myUrl');
  invitesEnabled = getMeta('invites_enabled');
  profile_directory = getMeta('profile_directory');
  useBlurhash = getMeta('use_blurhash');
  usePendingItems = getMeta('use_pending_items');
  showTrends = getMeta('trends');
  cropImages = getMeta('crop_images');
  disableSwiping = getMeta('disable_swiping');
  domain = getMeta('domain');
  domainName = getMeta('domain');
  accessToken = getMeta('access_token');
  eunomiaDomain = getMeta('eunomiaDomain');
  // not used / compat:
  isStaff = false;
  mascot = null;
  version = '0.5.3';
  repository = 'eunomia-social/dc';
  source_url = 'https://gitlab.com/eunomia-social/dc';
};

export {
  streaming_api_base_url,
  reduceMotion,
  autoPlayGif,
  displayMedia,
  expandSpoilers,
  unfollowModal,
  boostModal,
  deleteModal,
  me,
  myId,
  myUrl,
  searchEnabled,
  invitesEnabled,
  profile_directory,
  useBlurhash,
  usePendingItems,
  showTrends,
  forceSingleColumn,
  title,
  version,
  cropImages,
  disableSwiping,
  domain,
  domainName,
  accessToken,
  applicationServerKey,
  eunomiaDomain,
  isStaff,
  mascot,
  repository,
  source_url,
};

export default initialState;
