/* eslint-disable no-unused-vars */
'use strict';

const forge = require('node-forge');
const ECC = require('elliptic').ec;
const secureRandom = require('secure-random');

// performance
//const process = require('process');
const sizeof = require('object-sizeof');
const { encode, decode } = require('uint8-to-base64');

let cryptography = (function() {

  // javascript big integer type (from bn.js)
  const BigNum = require('../../../../../../node_modules/bn.js/lib/bn');

  // define curve (twisted edwards curve25519)
  const ed25519 = new ECC('ed25519');

  // define curve parameters (subgroup order)
  const order = new BigNum(2).pow(new BigNum(252)).add(new BigNum('27742317777372353535851937790883648493'));
  const group = BigNum.red(order);

  // cryptographic options
  const AES_KEY_SIZE = 128;
  const AES_BLOCK_MODE = 'AES-CBC';
  const RSA_PADDING = 'RSAES-PKCS1-V1_5';
  const PBKDF_ITERATIONS = 10000;

  // dictionary of functions exported by this module
  let calls = {};

  // split methods by category
  let rsa = {};
  let aes = {};
  let ecc = {};
  let utils = {};

  //------------------------------------------------------------------------------------------
  // KEYS (ECC + AES) & CERTIFICATES (RSA)
  //------------------------------------------------------------------------------------------

  /**
	 * Generates an EC key pair.
	 *
	 * @returns {Object} Ed25519 key pair (elliptic lib
	 * point object)
	**/
  ecc.generateKeyPair = function() {
    // generate key pair
    return ed25519.genKeyPair();
  };

  /**
	 * Extracts the public key from an X.509 certificate.
	 *
	 * @param {String} certificate - X.509 certificate in PEM format
	 * @returns {Object} Public Key (node-forge object)
	 **/
  rsa.keyFromCertificate = function(certificate) {
    // get certificate from pem
    let cert = forge.pki.certificateFromPem(certificate);

    // return key
    return cert.publicKey;
  };

  /**
	 * Generates a 128-bit key for AES.
	 *
	 * @returns {String} AES key (base64)
	 */
  aes.generateKey = function() {
    // generate 16 random bytes
    let key = forge.random.getBytesSync(AES_KEY_SIZE / 8);

    // return key as base64
    return forge.util.encode64(key);
  };

  //------------------------------------------------------------------------------------------
  // UTILITIES (HASH, KEY DERIVATION, ...)
  //------------------------------------------------------------------------------------------

  utils.pbkdf = function(password, salt) {
    // generate random salt and key
    salt = (salt === null) ? forge.random.getBytesSync(16) : forge.util.decode64(salt);
    let key = forge.pkcs5.pbkdf2(password, salt, PBKDF_ITERATIONS, 16);

    return {
      'key': forge.util.encode64(key),
      'salt': forge.util.encode64(salt),
    };
  };

  /**
	 * Hashes received data using the predefined SHA-256 algorithm.
	 *
	 * @param {String/number/Object} data - Information to be hashed
	 * @returns {String} Hashed data object (base64)
	**/
  utils.sha256 = function(data) {
    // create hash function object
    const hash = forge.md.sha256.create();
    let message = data;

    // convert object to json (if object)
    if (typeof data === 'object')
      message = JSON.stringify(data);

    // hash data
    let result = hash.update(message).digest();

    // return data in base64 format
    return forge.util.encode64(result.data);
  };

  //------------------------------------------------------------------------------------------
  // ENCRYPTION & DECRYPTION (AES)
  //------------------------------------------------------------------------------------------

  /**
	 * Encrypts data using AES-128-CBC. For security reasons, the initialization
	 * vector (IV) is generated locally so that it cannot be reused.
	 *
	 * @param {String/Object} data - Can be anything, will be converted to JSON first
	 * @param {String} key - Key used to encrypt (base64)
	 * @returns {{data: *, iv: String}} Encrypted data and initialization
	 * vector (both in base64)
	 **/
  aes.encrypt = function(data, key) {
    // prepare key and data
    key = forge.util.decode64(key);
    data = JSON.stringify(data);

    // generate init vector
    let iv = forge.random.getBytesSync(16);

    // create cipher
    const cipher = forge.cipher.createCipher(AES_BLOCK_MODE, key);
    cipher.start({ iv: iv });

    // encrypt
    cipher.update(forge.util.createBuffer(data));
    let result = cipher.finish();

    // convert to base64
    let encrypted = (result) ? forge.util.encode64(cipher.output.data) : null;
    iv = forge.util.encode64(iv);

    // return data and iv
    return {
      'data': encrypted,
      'iv': iv,
    };
  };

  /**
	 * Decrypts data encrypted with AES-128-CBC.
	 *
	 * @param {String} data - Encrypted data (base64)
	 * @param {String} key - Key used to decrypt (base64)
	 * @param {String} iv - Initialization vector used during encryption (base64)
	 * @returns {Object/String} Decrypted object
	 **/
  aes.decrypt = function(data, key, iv) {
    // convert parameters from base64 to bytes
    data = forge.util.decode64(data);
    key = forge.util.decode64(key);
    iv = forge.util.decode64(iv);

    // create and start decipher
    const decipher = forge.cipher.createDecipher(AES_BLOCK_MODE, key);
    decipher.start({ iv: iv });

    // decrypt
    decipher.update(forge.util.createBuffer(data));
    let result = decipher.finish();

    // prepare output
    let decrypted = null;
    if (result) {
      try {
        decrypted = JSON.parse(decipher.output.data);
      } catch (_){}
    }

    // return decrypted data
    return decrypted;
  };

  //------------------------------------------------------------------------------------------
  // ENCRYPTION & DECRYPTION (RSA)
  //------------------------------------------------------------------------------------------

  /**
	 * Encrypts data using RSA (PKCS1 Padding).
	 *
	 * @param {String/Object} data - Can be anything, will be converted to
	 * JSON first if necessary
	 * @param {Object} publicKey - Public key to encrypt (node-forge object)
	 * @returns {String} Encrypted object (base64)
	 **/
  rsa.encrypt = function(data, publicKey) {
    // convert to string
    data = JSON.stringify(data);

    // encrypt (returns bytes)
    let encryption = publicKey.encrypt(data, RSA_PADDING);

    // convert to base64
    return forge.util.encode64(encryption);
  };

  /**
	 * Decrypts data encrypted with RSA (PKCS1 Padding).
	 *
	 * @param {String} data - Encrypted data (base64)
	 * @param {Object} privateKey - Private key to decrypt (node-forge object)
	 *
	 * @returns {Object/String} Decrypted object
	**/
  rsa.decrypt = function(data, privateKey) {
    // base64 to bytes
    let bytes = forge.util.decode64(data);

    // decrypt (returns bytes)
    let decryption = privateKey.decrypt(bytes, RSA_PADDING);

    // handle return data
    try {
      decryption = JSON.parse(decryption);
    } catch (_) {}

    return decryption;
  };

  //------------------------------------------------------------------------------------------
  // SIGNATURES (RSA)
  //------------------------------------------------------------------------------------------

  /**
	 * Verifies data signed with RSA (PKCS1) and SHA256.
	 *
	 * @param {Object} data - Information that was signed
	 * @param {String} signature - Result of the sign function (base64)
	 * @param {Object} publicKey - Public key to verify signature (node-forge object)
	 * @returns {boolean} True if signature verifies, false otherwise
	**/
  rsa.verify = function(data, signature, publicKey) {
    // convert signature and data to bytes
    let bytes = forge.util.decode64(signature);
    let digest = forge.util.decode64(utils.sha256(data));

    // return boolean to verify signature
    try {
      return publicKey.verify(digest, bytes);
    } catch (e) {
      return false;
    }
  };

  //------------------------------------------------------------------------------------------
  // ELLIPTIC CURVE CRYPTOGRAPHY
  //------------------------------------------------------------------------------------------

  /**
	 * Receives a private key and returns the equivalent
	 * public key in compressed format.
	 *
	 * @param {String} privateKey - Private key (base64)
	 * @return {String} Compressed public key
	 */
  ecc.keyFromPrivate = function(privateKey) {
    // calculate public key and return compressed
    let pk = ed25519.keyFromPrivate(Buffer.from(privateKey, 'base64')).getPublic();
    return ecc.compress(pk);
  };

  /**
	 * Hashes a string to a point in the Ed25519 Elliptic
	 * Curve.
	 *
	 * @param {String} data - Data to be hashed
	 * @returns {Object} Point on the curve (elliptic library
	 * point object)
	 */
  ecc.hashToPoint = function(data) {
    // calculate exponent
    let hash = utils.sha256(data);
    let exp = new BigNum(Buffer.from(hash, 'base64')).toRed(group);

    // calculate and return point
    return ed25519.g.mul(exp);
  };

  /**
	 * Computes, deterministically, a pseudonym for a specific voter
	 * which depends on the post and the voter's private key.
	 *
	 * @param {String} privateKey - Voter's private key (base64)
	 * @param {String} postId - Identifier of the post
	 * @returns {{nym: Object, h: Object}} Point on the curve (elliptic library
	 * point object)
	 */
  ecc.computeNym = function(privateKey, postId) {
    // convert private key to bignum
    let skbn = new BigNum(Buffer.from(privateKey, 'base64'));

    // get base point and calculate nym
    let h = ecc.hashToPoint(postId);
    let nym = h.mul(skbn);

    return { 'nym': nym, 'h': h };
  };

  /**
	 * Transforms an Ed25519 point into its compressed form which
	 * has 256 bits: bits 0-254 are the y coordinate and bit 255 is
	 * the parity of x.
	 *
	 * @param {Object} point - Point on the curve (elliptic library
	 * point object)
	 * @returns {String} Compressed point (base64)
	 */
  ecc.compress = function(point) {
    // normalize point first
    point.normalize();
    // add parity bit to point
    let y = point.getY();
    if (point.getX().isOdd())
      y.bincn(255);
    // return compressed point
    let ret= y.toArrayLike(Uint8Array, 'be', 32);
    return encode(ret);
  };

  /**
	 * Gets an Ed25519 compressed point and return the corresponding
	 * point as an object.
	 *
	 * @param {String} point - Compressed curve point (256 bits:
	 * bit 255 is x's parity and the remaining bits are the y
	 * coordinate) (base64)
	 * @returns {Object} Point on the curve (elliptic library
	 * point object)
	 */
  ecc.decode = function(point) {
    // get compressed point from base64
    let y = new BigNum(Buffer.from(point, 'base64'));
    let parity = y.testn(255);

    // build and return point
    return ed25519.curve.pointFromY(y.maskn(255), parity);
  };

  //------------------------------------------------------------------------------------------
  // ZERO KNOWLEDGE PROOF
  //------------------------------------------------------------------------------------------

  /**
	 * Computes a zero knowledge proof that allows a voter to vote
	 * privately, while guaranteeing that such voter has not voted
	 * before.
	 *
	 * @param {Array<String>} publicKeys - List of public keys used in the proof
	 * @param {{pk: String, sk: String}} voter - Object that represents the key pair
	 * of the voter
	 * @param {Object} ballot - Ballot to be signed
	 * @returns {{nym: String, key_commits: Array<String>, nym_commits: Array<String>,
	 * challenges: Array<String>, responses: Array<String>, keys: Array<String>}}
	 * ZK Proof which will be verified by the voting server
	 */
  ecc.computeProof = function(publicKeys, voter, ballot) {
    // count time
    //let start = process.hrtime();

    // get proof size (n) and generate random index
    let proofSize = publicKeys.length + 1;
    let idx = Math.floor(Math.random() * proofSize);

    // get private key and append voter key
    let privateKey = voter.sk;
    publicKeys.splice(idx, 0, voter.pk);
    let keys = [...publicKeys];
    // generate random values for k and c
    let k = [], c = [];
    for (let i = 0; i < proofSize; i++) {
      k.push(new BigNum(secureRandom(32)).toRed(group));
      c.push(new BigNum(secureRandom(32)).toRed(group));
      keys[i] = ecc.decode(keys[i]);
    }
    // compute h and nym
    let pseudonym = ecc.computeNym(privateKey, ballot.post_id);
    let h = pseudonym.h;
    let nym = pseudonym.nym;
    // compute commitments
    // r --> public key, R --> nym (commits)
    let r = [], R = [];
    for (let i = 0; i < proofSize; i++) {
      if (i === idx) {
        r.push(ed25519.g.mul(k[i]).normalize());
        R.push(h.mul(k[i]).normalize());
      } else {
        r.push((ed25519.g.mul(k[i])).add(keys[i].neg().mul(c[i])).normalize());
        R.push((h.mul(k[i])).add(nym.neg().mul(c[i])).normalize());
      }

      // standardize points
      r[i] = ecc.compress(r[i]);
      R[i] = ecc.compress(R[i]);
    }
    // calculate non-interactive challenge
    let challenge = utils.sha256(r.join('') + R.join('') + publicKeys.join('') + ecc.compress(nym) + JSON.stringify(ballot));
    c[idx] = new BigNum(Buffer.from(challenge, 'base64')).toRed(group);
    // calculate valid challenge
    for (let i = 0; i < proofSize; i++)
      if (i !== idx)
        c[idx] = c[idx].redSub(c[i]);

    // compute response
    let s = [...k];
    let skbn = new BigNum(Buffer.from(privateKey, 'base64'));
    s[idx] = k[idx].add(c[idx].mul(skbn)).toRed(group);
    // standardize numbers
    for (let i = 0; i < proofSize; i++) {
      c[i] = encode(c[i].toArrayLike(Uint8Array, 'be', 32));
      s[i] = encode(s[i].toArrayLike(Uint8Array, 'be', 32));
    }
    // build proof
    let proof = {
      'nym': ecc.compress(nym),
      'key_commits': r,
      'nym_commits': R,
      'challenges': c,
      'responses': s,
      'keys': publicKeys,
    };
    // measure time
    //let end = process.hrtime(start);
    //console.log(`Took ${end[0]}s and ${end[1] / 1000000}ms to compute a ZKP with ${proofSize} keys and size ${sizeof(proof)/1024} kB, for postId ${ballot.post_id}`);

    // return proof
    return proof;
  };

  //------------------------------------------------------------------------------------------

  // add methods to be exported
  calls.rsa = rsa;
  calls.aes = aes;
  calls.ecc = ecc;
  calls.utils = utils;

  return calls;
})();

module.exports = cryptography;
