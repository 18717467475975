import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { formatDistanceToNow } from 'date-fns';
import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LoadingIndicator from '../../../components/loading_indicator';
import Avatar from '../../../components/avatar';
import emojify from '../../../features/emoji/emoji';
import positive from '../../../../images/positive.png';
import negative from '../../../../images/negative.png';
import neutral from '../../../../images/neutral.png';
import trustIcon from '../../../../images/trust.png';
import mistrustIcon from '../../../../images/mistrust.png';
import IconButton from '../../../components/icon_button';
import { inSinglePanel, plainDomain, setBodyOverflowY, sortPosts } from '../lib';
// import { plainDomain, setBodyOverflowY, sortPosts } from '../lib';

const postBackground = 'rgba(84, 72, 149, 0.4)';
const subjectivityBackgroundColor = '#4672c3';
const selectedPostBackground = '#fff';

export default @injectIntl
class Cascade extends React.PureComponent {

  static contextTypes = {
    router: PropTypes.object,
  };

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    posts: ImmutablePropTypes.list.isRequired,
    eunomia: ImmutablePropTypes.map.isRequired,
    intl: PropTypes.object.isRequired,
    statusId: PropTypes.string,
  };

  state = {
    sortedPosts: null,
    preCurrent: false,
    postCurrent: false,
  };


  onMountOrUpdate(force = false) {
    if (!this.state.sortedPosts || force) {
      const { preCurrent, postCurrent } = this.state;
      const sortedPosts = sortPosts(this.props.posts);
      this.setState({ preCurrent, postCurrent, sortedPosts });
    }
    setBodyOverflowY();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.onMountOrUpdate(prevProps.eunomia !== this.props.eunomia || prevProps.posts !== this.props.posts);
  }

  componentDidMount() {
    this.onMountOrUpdate(true);
  }

  onShow(pre, post, more=true) {
    const state = {
      preCurrent: this.state.preCurrent,
      postCurrent: this.state.postCurrent,
    };
    if (pre) {
      state.preCurrent = more;
    } else
    if (post) {
      state.postCurrent = more;
    }
    this.setState({ ...state });
  }

  onStatusLink(status) {
    if (!status) {
      return;
    }
    this.context.router.history.push(`/statuses/${status.getIn(['reblog', 'id'], status.get('id'))}`);
  }

  onAccountClick(status) {
    if (!status) {
      return;
    }
    const accountId = status.getIn(['account', 'id']);
    this.context.router.history.push(`/accounts/${accountId}`);
  }

  renderPost(post, isTheSelected = false) {
    const postSourceId = post.get('source_id', null);
    const postSourceUrl = post.get('source_url', null);
    if (!postSourceId || !postSourceUrl) {
      return null;
    }
    const status = post.get('original_post', null);
    if (!status) {
      return null;
    }
    // let createdAt = status.get('created_at');
    const accountName = status.getIn(['account', 'acct']);
    const statusClassName = 'status__content__text status__content__text--visible status__eunomia__cascade__content';
    const eunomiaClassName =  isTheSelected ? 'eunomia-cascade-selected-post-view': 'eunomia-cascade-post-view';
    return (
      <div className={classNames('status__wrapper')}>
        <div className={classNames('status')} style={{ borderBottom: 'none' }}>
          <div className='status__info'>
            <a
              data-id={status.getIn(['account', 'id'])} href={status.getIn(['account', 'url']) || '#'}
              title={status.getIn(['account', 'acct'])} className={`status__display-name ${eunomiaClassName}`}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={(e) => {
                e.preventDefault(); this.onAccountClick(status);
              }}
              rel='noopener noreferrer'
            >
              <div className='status__avatar'>
                <Avatar account={status.get('account')} size={48} />
              </div>
              <span className={`display-name ${eunomiaClassName}`}>
                {accountName ?? 'Unknown'}
              </span>
            </a>
          </div>
          {/* eslint-disable-next-line react/jsx-no-bind */}
          <div className={classNames('status__content')} role={'link'} tabIndex='0' onClick={() => this.onStatusLink(status)} style={{ cursor: 'pointer' }} >
            <div className={`${statusClassName} ${eunomiaClassName}`} style={{ direction: 'ltr' }} dangerouslySetInnerHTML={{ __html:  emojify(status.get('content')) }} />
          </div>
        </div>
      </div>
    );
  }

  renderIndicators(post) {
    const { posts } = this.props;
    const { sortedPosts } = this.state;
    if (!posts || !sortedPosts) {
      return (
        <div style={{ padding: 10 }}>
          <LoadingIndicator />
        </div>
      );
    }
    const postSentiment = post.get('sentiment_class', null);
    // eslint-disable-next-line no-nested-ternary
    const sentimentImg = postSentiment !== null ? postSentiment < 0 ? negative : postSentiment > 0 ? positive : neutral : null;
    // eslint-disable-next-line no-nested-ternary
    const sentimentTitle = postSentiment !== null ? postSentiment < 0 ? 'Negative' : postSentiment > 0 ? 'Positive' : 'Neutral' : null;
    const subjectivityClass = post.get('subjectivity_score', null);
    const trusts = post.getIn(['votes', 'trusts'], null);
    const mistrusts = post.getIn(['votes', 'mistrusts'], null);
    const createdAt = post.getIn(['original_post', 'created_at'], null);
    if (!createdAt){
      return null;
    }
    let when =  formatDistanceToNow(new Date(createdAt), { includeSeconds: false, addSuffix: false });
    if (when.startsWith('about ')) {
      when = when.replace('about ', '');
    }
    const trustIconView = () => {
      let trustsStr = '';
      try {
        const _trustsCount = parseInt(trusts);
        trustsStr = !isNaN(_trustsCount) ? `${_trustsCount}` : '0';
      } catch {}
      return (
        <div className='eunomia-menu-icon-wrapper'>
          <img
            src={trustIcon}
            alt=''
            className='eunomia-menu-icon'
          />
          <span
            className='eunomia-menu-icon-counter'
          >
            { trustsStr }
          </span>
        </div>
      );
    };
    const mistrustIconView = () => {
      let mistrustsStr = '';
      try {
        const _mistrustsCount = parseInt(mistrusts);
        mistrustsStr = !isNaN(_mistrustsCount) ? `${_mistrustsCount}` : '0';
      } catch {}
      return (
        <div className='eunomia-menu-icon-wrapper'>
          <img
            src={mistrustIcon}
            alt=''
            className='eunomia-menu-icon'
          />
          <span
            className='eunomia-menu-icon-counter'
          >
            { mistrustsStr }
          </span>
        </div>
      );
    };
    const sentimentView = () => (
      <div className='eunomia-menu-icon-wrapper'>
        <img
          src={sentimentImg}
          alt=''
          className='eunomia-menu-icon'
        />
      </div>
    );
    const subjectivityView = () => {
      // eslint-disable-next-line no-nested-ternary
      const subjectivityString = subjectivityClass ? subjectivityClass < 0.5 ? 'O' : 'S' : null;
      return (
        <div className='eunomia-menu-icon-wrapper' style={{ padding: 0, marginTop: 2 }}>
          <div style={{ backgroundColor: subjectivityBackgroundColor, width: 25, height: 25, fontSize: 22, color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            { subjectivityString }
          </div>
        </div>
      );
    };
    return (
      <div className='status__action-bar' style={{ backgroundColor: '#d0d0d0', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div className='eunomia-cascade-action-bar-wrapper' style={{ display: 'flex', paddingBottom: 5, paddingTop: 5, backgroundColor: '#d0d0d0', flex: 2 }}>
          {when}
        </div>
        <div className='eunomia-cascade-action-bar-wrapper' style={{ display: 'flex', paddingBottom: 5, paddingTop: 5, backgroundColor: '#d0d0d0', flex: 1 }}>
          {
            postSentiment !== null &&
            <IconButton
              className='status__action-bar-button eunomia-icon-button'
              size={24}
              disabled
              title={`${sentimentTitle ?? ''} Sentiment`}
              // eslint-disable-next-line react/jsx-no-bind
              icon={sentimentView}
            />
          }
          {
            subjectivityClass !== null &&
            <IconButton
              className='status__action-bar-button eunomia-icon-button'
              size={24}
              disabled
              title={
                // eslint-disable-next-line no-nested-ternary
                subjectivityClass ? subjectivityClass < 0.5 ? 'Objective' : 'Subjective' : 'Subjectivity Indicator'
              }
              // eslint-disable-next-line react/jsx-no-bind
              icon={subjectivityView}
            />
          }
          {
            trusts !== null &&
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#d0d0d0' }}>
              <IconButton
                className='status__action-bar-button eunomia-icon-button'
                size={19}
                disabled
                title={'Trusts'}
                // eslint-disable-next-line react/jsx-no-bind
                icon={trustIconView}
              />
            </div>
          }
          {
            mistrusts !== null &&
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#d0d0d0' }}>
              <IconButton
                className='status__action-bar-button eunomia-icon-button'
                size={20}
                disabled
                title={'Mistrusts'}
                // eslint-disable-next-line react/jsx-no-bind
                icon={mistrustIconView}
              />
            </div>
          }
        </div>
      </div>
    );
  }

  getPostsToRender(eunomiaId) {
    const { sortedPosts, preCurrent, postCurrent } = this.state;
    let targetIndex = -1;
    let _posts = [];
    let targetPost = null;
    for (let i=0; i < sortedPosts.size; i ++) {
      const _post = sortedPosts.get(i);
      const postSourceId = _post.get('source_id');
      const postSourceUrl = _post.get('source_url');
      const postId = `${postSourceId}@${plainDomain(postSourceUrl)}`;
      if (postId === eunomiaId) {
        targetIndex = i;
        targetPost = _post;
        break;
      }
    }
    if (sortedPosts.size > 3 && targetPost !== null) {
      sortedPosts.forEach((post, index) => {
        // 1st one: always visible
        if (index === 0) {
          _posts.push(post);
        }
        // last one: always visible
        if (index === sortedPosts.size - 1) {
          _posts.push(post);
        }
        // selected post: always visible (if not yet added: 1st or last)
        if (index === targetIndex && targetIndex > 0 && targetIndex < sortedPosts.size -1) {
          _posts.push(post);
        }
        // the selected one is the 1st post, add the 2nd one
        if (targetIndex === 0 && index === 1) {
          _posts.push(post);
        }
        // the selected one is the last post, add the previous one
        if (targetIndex === sortedPosts.size - 1 && index === sortedPosts.size - 2) {
          _posts.push(post);
        }
        // add posts created before the selected one
        // if "show more pre"
        if (preCurrent && index < targetIndex && !_posts.includes(post)) {
          _posts.push(post);
        }
        // add posts created after the selected one
        if (postCurrent && index > targetIndex && !_posts.includes(post)) {
          _posts.push(post);
        }
      });
    } else {
      // if (!targetPost) {
      //   // eslint-disable-next-line no-console
      //   console.log(eunomiaId);
      // }
      _posts = sortedPosts;
    }
    return { _posts, targetIndex };
  }

  renderPosts() {
    const { posts, eunomia } = this.props;
    const { sortedPosts, preCurrent, postCurrent } = this.state;
    if (!posts || !sortedPosts) {
      return (
        <div style={{ padding: 10 }}>
          <LoadingIndicator />
        </div>
      );
    }
    const eunomiaSourceId = eunomia.get('source_id', null);
    const eunomiaSourceUrl = eunomia.get('source_url', null);
    const eunomiaId = `${eunomiaSourceId}@${plainDomain(eunomiaSourceUrl)}`;
    const { _posts, targetIndex } = this.getPostsToRender(eunomiaId);
    return _posts.map((post, index) => {
      const postSourceId = post.get('source_id');
      const postSourceUrl = post.get('source_url');
      const postId = `${postSourceId}@${plainDomain(postSourceUrl)}`;
      let showMorePre = false;
      let showMorePost = false;
      let showLessPre = false;
      let showLessPost = false;
      if (sortedPosts.size > 3) {
        showMorePre = !preCurrent && targetIndex > 1 && index === 0;
        // showLessPre = preCurrent && targetIndex > 1 && index === targetIndex - 1;
        showLessPre = preCurrent && targetIndex > 1 && index === 0;
        showMorePost = !postCurrent && targetIndex < sortedPosts.size - 2 && postId === eunomiaId;
        // showMorePost = !postCurrent && targetIndex < sortedPosts.size - 2 && index === _posts.length - 2;
        showLessPost = postCurrent && targetIndex < sortedPosts.size - 2 && postId === eunomiaId;
        // showLessPost = postCurrent && targetIndex < sortedPosts.size - 2 && index === _posts.length - 2;
      }
      return (
        <div key={postId} style={{ marginBottom: 20 }}>
          <div style={{
            backgroundColor: postId === eunomiaId ? selectedPostBackground : postBackground,
            marginBottom: 5,
            borderRadius: 2,
            padding: 10,
            border: postId === eunomiaId ? 'thin solid #0000ff' : 'none',
          }}
          >
            {this.renderPost(post, postId === eunomiaId)}
          </div>
          {this.renderIndicators(post)}
          { showMorePre || showMorePost ?
            (<div style={{ padding: '10px 0', textAlign: 'center' }}>
              <button
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => this.onShow(showMorePre, showMorePost, true)}
                className='load-more cascade-load-more-button'
              >
                <span>Load more</span>
              </button>
            </div>
            ) : null }
          { showLessPre || showLessPost ?
            (<div style={{ padding: '10px 0', textAlign: 'center' }}>
              <button
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => this.onShow(showLessPre, showLessPost, false)}
                className='load-more cascade-load-more-button'
              >
                <span>Load less</span>
              </button>
            </div>
            ) :null }
        </div>
      );
    });
  };

  render () {
    const onClose = () => {
      setBodyOverflowY('scroll');
      this.props.onClose();
    };
    const singlePanelTopStyle = () => {
      const topRect = document.querySelector('.tabs-bar').getBoundingClientRect();
      return {
        position: 'fixed',
        top: `${topRect.height + topRect.y}px`,
        width: `${topRect.width - 20}px`,
        padding: '0 10px',
      };
    };
    return (
      <>
        <div className={'eunomia-information-cascade-view-top'} style={inSinglePanel() ? singlePanelTopStyle(): {}}>
          <div style={{ float: 'left' }}>
            <p style={{ fontSize: 'medium' }}>Latest Similar <span style={{ fontWeight: 'bold' }}>
              <i className='fa fa-long-arrow-down' aria-hidden='true' /></span>
            </p>
          </div>
          {/* eslint-disable-next-line react/jsx-no-bind */}
          <div role='button' id={'eunomia-cascade-modal-close-button'} onClick={onClose} style={{ float: 'right', padding: 5, cursor:'pointer' }}>
            {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus,react/jsx-no-bind */}
            <div className='eunomia-indicators-close eunomia-cascade-modal-close' />
          </div>
        </div>
        <div className={'eunomia-information-cascade-view-wrapper'}>
          <div className='eunomia-information-cascade-view' style={inSinglePanel() ? { position: 'absolute' }: {}}>
            {this.renderPosts()}
            <div style={{ float: 'left', height: 30 }}>
              <p style={{ fontSize: 'medium' }}>Earliest Similar <span style={{ fontWeight: 'bold' }}>
                <i className='fa fa-long-arrow-up' aria-hidden='true' /></span>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

}
