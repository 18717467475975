import {
  EUNOMIA_TOKEN,
  EUNOMIA_PENDING,
} from '../constants';

export * from './post';
export * from './vote';
export * from './cascade';

export function onEunomiaToken(data) {
  return { type: EUNOMIA_TOKEN, payload: data };
}

export function setEunomiaPending(url) {
  return { type: EUNOMIA_PENDING, url };
}


