import { STORE_HYDRATE } from '../actions/store';
import { Map as ImmutableMap } from 'immutable';

const initialState = ImmutableMap({
  accept_content_types: [],
});

const media_attachments = {
  'accept_content_types':[
    '.jpg', '.jpeg', '.png', '.gif', '.webm', '.mp4', '.m4v', '.mov',
    '.ogg', '.oga', '.mp3', '.wav', '.flac', '.opus', '.aac', '.m4a', '.3gp', '.wma',
    'image/jpeg', 'image/png', 'image/gif', 'video/webm', 'video/mp4', 'video/quicktime',
    'video/ogg', 'audio/wave', 'audio/wav', 'audio/x-wav', 'audio/x-pn-wave', 'audio/ogg',
    'audio/mpeg', 'audio/mp3', 'audio/webm', 'audio/flac', 'audio/aac', 'audio/m4a',
    'audio/x-m4a', 'audio/mp4', 'audio/3gpp', 'video/x-ms-asf',
  ],
};

export default function meta(state = initialState, action) {
  switch(action.type) {
  case STORE_HYDRATE:
    return state.merge(media_attachments);
  default:
    return state;
  }
};
