import React from 'react';
import Login from './login';
import Callback from './callback';
import { Redirect, withRouter, Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';



export default @injectIntl
@withRouter
class Auth extends React.PureComponent {

  static propTypes = {
    onEnd: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.renderCallback = this.renderCallback.bind(this);
  }

  renderCallback (props) {
    return <Callback onEnd={this.props.onEnd} {...props} />;
  }

  render() {
    return (
      <div className='ui'>
        <Switch>
          <Redirect from='/' to='/welcome' exact />
          <Route path='/welcome'  component={Login} />
          <Route path='/callback' render={this.renderCallback} />
        </Switch>
      </div>
    );
  }

}