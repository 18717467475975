import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import SearchContainer from '../../../features/compose/containers/search_container';
import ComposeFormContainer from '../../../features/compose/containers/compose_form_container';
import NavigationContainer from '../../../features/compose/containers/navigation_container';
import LinkFooter from './link_footer';
import { changeComposing } from '../../../actions/compose';
import Cascade from '../../../features/eunomia/components/cascade';
import { setCascadeViewOpen } from '../../eunomia';
import {setBodyOverflowY} from "../../eunomia/lib";

const mapStateToProps = state => {
  const statusId = state.getIn(['eunomia', 'cascadeOpen'], null);
  let eunomia = null;
  let cascadeId = null;
  let processed = false;
  if (statusId) {
    eunomia = state.getIn(['eunomia', statusId], null);
    if (eunomia) {
      cascadeId = eunomia.get('cascade_id', null);
      processed = eunomia.get('processed', 'no') === 'yes';
    }
  }
  return  {
    eunomia,
    cascadePosts: processed && cascadeId ? state.getIn(['cascades', cascadeId], null) : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetCascade(open) {
    dispatch(setCascadeViewOpen(open));
  },
  dispatch,
});

export default @connect(mapStateToProps, mapDispatchToProps)
class ComposePanel extends React.PureComponent {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    eunomia: ImmutablePropTypes.map,
    cascadePosts: ImmutablePropTypes.list,
    onSetCascade: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onCloseCascade = this.onCloseCascade.bind(this);
  }

  onFocus = () => {
    this.props.dispatch(changeComposing(true));
  }

  onBlur = () => {
    this.props.dispatch(changeComposing(false));
  }

  onCloseCascade() {
    setBodyOverflowY('scroll');
    this.props.onSetCascade(null);
  }

  render() {
    return this.props.cascadePosts && this.props.cascadePosts.size > 0 ? (
      <div className='compose-panel' onFocus={this.onFocus}>
        <Cascade onClose={this.onCloseCascade} eunomia={this.props.eunomia} posts={this.props.cascadePosts} />
      </div>
    ) : (
      <div className='compose-panel' onFocus={this.onFocus}>
        <SearchContainer openInRoute />
        <NavigationContainer onClose={this.onBlur} />
        <ComposeFormContainer singleColumn />
        <LinkFooter withHotkeys />
      </div>
    );
  }

}
