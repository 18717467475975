import { fromJS } from 'immutable';
import { EUNOMIA_STATS_IMPORT, CURRENT_USER_VOTED } from '../constants';

const initialState = fromJS({ stats: null });

const incrementUserVote = (state) => {
  const currentState = state.toJS();
  if (currentState.stats) {
    if (currentState.stats.user_votes_this_month) {
      currentState.stats.user_votes_this_month += 1;
    }
    if (currentState.stats.user_votes_this_week) {
      currentState.stats.user_votes_this_week += 1;
    }
  }
  return state.set('stats', fromJS(currentState.stats));
};

export default function eunomiaStats(state = initialState, action) {
  if (action.type === EUNOMIA_STATS_IMPORT && action.payload) {
    return state.set('stats', fromJS(action.payload));
  } else if (action.type === CURRENT_USER_VOTED) {
    return incrementUserVote(state);
  } else {
    return state;
  }
}
