import axios from 'axios';
import { APPS_DB_KEY, EUNOMIA_APP_TERMS } from '../constants';
import Storage from '../storage';
import { plainDomain } from '../lib';

const REDIRECT_URI_BASE = `${window.location.origin}/eunomia/callback`;

export const notFirstRun = (cb) => {
  Storage.get(EUNOMIA_APP_TERMS)
    .then((entry) => {
      cb(entry !== null && entry !== undefined);
    })
    .catch(() =>{
      cb(false);
    });
};

export const getEunomiaInstances = (cb) => {
  axios.get('/eunomia/api/instances', { timeout: 3000 }).then(({ data }) => {
    if (data && data.instances) {
      cb(data.instances);
    } else {
      cb([]);
    }
  }).catch(()=> {
    cb([]);
  });
};

export const acceptedTerms = (cb) => {
  Storage.set(EUNOMIA_APP_TERMS, 'yes')
    .then((entry) => {
      cb(entry !== null && entry !== undefined);
    })
    .catch(() => {
      cb(false);
    });
};


export const decodeCallback = (code) => {
  try {
    return atob(code);
  } catch (reason) {
    console.warn(reason);
    return null;
  }
};

const encodeCallback = (snDomain, eunomiaDomain) => {
  const encoded = btoa(`?domain=${snDomain}&eunomia=${eunomiaDomain}`);
  return `?edoc=${encoded}`;
};

const getStoredApps = (baseUri) => {
  const domain = plainDomain(baseUri);
  return new Promise((resolve, reject) => {
    Storage.get(APPS_DB_KEY).then((apps) => {
      if (apps) {
        const app = apps[domain];
        if (app) {
          resolve({ apps, app });
        } else {
          resolve({ apps, app: undefined });
        }
      } else {
        resolve({ apps: {}, app: undefined });
      }
    }).catch((e) => reject(e));
  });
};

export const storeTokenAndVapidKey = (baseUri, accessToken, vapidKey) => {
  return new Promise((resolve, reject) => {
    let apps = {};
    let app = {};
    const domain = plainDomain(baseUri);
    getStoredApps(domain).then((results) => {
      apps = results.apps;
      app = results.app;
      if (!app) {
        app = {};
      }
    }).catch(console.error).finally(() => {
      app.access_token = accessToken;
      app.vapid_key = vapidKey;
      apps[domain] = app;
      Storage.set(APPS_DB_KEY, apps).then(() => resolve(app)).catch((e) => reject(e));
    });
  });
};

const isEunomiaHosted = (url) => new Promise((resolve) => {
  const versionUrl = `${url}/eunomia/api/version`;
  axios
    .get(versionUrl)
    .then(() => {
      resolve( true);
    })
    .catch(() => {
      resolve( false);
    });
});

export const checkEunomiaUrl = (url) =>  new Promise((resolve, reject) => {
  isEunomiaHosted(url).then((itIs) => {
    if (itIs) {
      resolve(true);
    } else {
      reject({ message: 'Not a EUNOMIA Instance' });
    }
  }).catch((reason) => {
    reject(reason);
  });
});

export const checkMastodonUrl = (url, eunomiaOnly = false) => new Promise((resolve, reject) => {
  const cleanUrl = `https://${plainDomain(url)}`;
  const instanceUrl = `${cleanUrl}/api/v1/instance`;
  axios
    .get(instanceUrl, { timeout: 3000 })
    .then((response) => {
      if (response.data && response.data.version) {
        isEunomiaHosted(cleanUrl).then((itIs) => {
          if (itIs || !eunomiaOnly) {
            resolve(itIs);
          } else {
            reject({ message: 'Not EUNOMIA hosted' });
          }
        }).catch(null);
      }
    })
    .catch((reason) => {
      if (
        reason.response &&
        reason.response.status &&
        reason.response.status === 401
      ) {
        isEunomiaHosted(cleanUrl).then((itIs) => {
          if (itIs || !eunomiaOnly) {
            resolve(itIs);
          } else {
            reject({ message: 'Not EUNOMIA hosted' });
          }
        }).catch(null);
      } else {
        reject(reason);
      }
    });
});


const _get_mastodon_app = (mastodonDomain, redirect_uri, cb) => {
  axios.post(`https://${mastodonDomain}/api/v1/apps`, {
    'client_name': 'EUNOMIA Digital Companion',
    'redirect_uris': redirect_uri,
    'scopes': 'read write follow push',
  }, { headers: { 'content-type': 'application/json' } }).then(({ data }) => {
    if (data.client_id && data.client_secret){
      const { client_id, client_secret } = data;
      let vapid_key = data.vapid_key;
      if (!vapid_key) {
        vapid_key = '-';
      }
      Storage.set('TEMP_APP', { domain: mastodonDomain, client_id, client_secret, vapid_key }).finally(() => {
        cb({ client_id: data.client_id, client_secret: data.client_secret });
      }).catch((reason) => {
        cb(null);
        console.warn(reason);
      });
    }
  }).catch((reason) => {
    cb(null);
    console.warn(reason);
  });
};

export const getAppToken = (mastodonDomain, eunomiaDomain, code, cb) => {
  Storage.get('TEMP_APP').then(data => {
    if (
      data &&
      data.domain &&
      data.client_id &&
      data.client_secret &&
      data.vapid_key &&
      data.domain === mastodonDomain
    ) {
      const { client_id, client_secret, vapid_key } = data;
      const redirect_uri = `${REDIRECT_URI_BASE}${encodeCallback(mastodonDomain, eunomiaDomain)}`;
      axios.post(`https://${mastodonDomain}/oauth/token`, {
        client_id, client_secret, redirect_uri, grant_type: 'authorization_code', code,
      }).then(({ data }) => {
        if (data.access_token) {
          cb({ access_token: data.access_token, vapid_key });
        } else {
          cb(null);
        }
      }).catch((reason) => {
        console.warn(reason);
        cb(null);
      });
    }
  }).catch((reason) => {
    console.warn(reason);
    cb(null);
  });
};

export const getAuthUrl = (snUri, eunomiaUri, cb) => {
  const eunomiaDomain = plainDomain(eunomiaUri);
  const snDomain = plainDomain(snUri);
  const redirect_uri = `${REDIRECT_URI_BASE}${encodeCallback(snDomain, eunomiaDomain)}`;
  _get_mastodon_app(snDomain, redirect_uri, (data) => {
    if (!data) {
      cb(null);
    } else {
      const { client_id } = data;
      const scopes = 'read+write+follow+push';
      cb(`https://${snDomain}/oauth/authorize?response_type=code&scope=${scopes}&&client_id=${client_id}&redirect_uri=${redirect_uri}`);
    }
  });
};

export const getInstance = (token, baseUri) => {
  const domain = plainDomain(baseUri);
  const headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` };
  const url = `https://${domain}/api/v1/instance`;
  return new Promise((resolve, reject) => {
    axios.get(url, { headers }).then((response) => {
      resolve(response.data);
    }).catch((reason) => {
      reject(reason);
    });
  });
};

export const getUserPrefs = (token, baseUri) => {
  const domain = plainDomain(baseUri);
  const headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` };
  const url = `https://${domain}/api/v1/preferences`;
  return new Promise((resolve, reject) => {
    axios.get(url, { headers }).then((response) => {
      resolve(response.data);
    }).catch((reason) => {
      reject(reason);
    });
  });
};
