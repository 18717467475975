import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { logOut } from '../../../utils/log_out';
import { openModal } from '../../../actions/modal';
import footerLight from '../../../../images/footer_light.png';
import footerDark from '../../../../images/footer_dark.png';
import { source_url, repository, version, accessToken } from '../../../initial_state';

const messages = defineMessages({
  logoutMessage: { id: 'confirmations.logout.message', defaultMessage: 'Are you sure you want to log out?' },
  logoutConfirm: { id: 'confirmations.logout.confirm', defaultMessage: 'Log out' },
});

const mapDispatchToProps = (dispatch, { intl }) => ({
  onLogout () {
    dispatch(openModal('CONFIRM', {
      message: intl.formatMessage(messages.logoutMessage),
      confirm: intl.formatMessage(messages.logoutConfirm),
      onConfirm: () => logOut(),
    }));
  },
});

export default @injectIntl
@connect(null, mapDispatchToProps)
class LinkFooter extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  static contextTypes = {
    router: PropTypes.object,
  };

  state = {
    eeStep: 0,
  };

  static propTypes = {
    withHotkeys: PropTypes.bool,
    onLogout: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  handleLogoutClick = e => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onLogout();

    return false;
  }

  toEe = () => {
    const { eeStep } = this.state;
    if (eeStep > 5) {
      this.setState({ eeStep: 0 });
      this.context.router.history.push('/reface');
    } else {
      this.setState({ eeStep: eeStep + 1 });
    }
  }

  render () {
    let imgSrc = footerDark;
    let themeName = 'dark';
    const bodyClass = document.body.className;
    window.localStorage.setItem('MASTODON_LOGIN_STATUS', '1');
    if (bodyClass.includes('theme-mastodon-light')) {
      imgSrc = footerLight;
      themeName = 'light';
    }
    window.localStorage.setItem('EUNOMIA_MASTODON_THEME', themeName);
    return (
      <div className='getting-started__footer'>
        <div onClick={this.toEe} role='button' style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          <img alt='EUNOMIA Logo' src={imgSrc} style={{ width: '90%', marginBottom: 10, maxWidth: 320 }} />
        </div>
        <p>
          <FormattedMessage
            id='getting_started_eunomia.open_source_notice'
            defaultMessage='EUNOMIA Digital Companion is open source software. You can contribute or report issues on GitLab at {gitlab}.'
            values={{ gitlab: <span><a href={source_url} rel='noopener noreferrer' target='_blank'>{repository}</a> (v{version})</span> }}
          />
        </p>
      </div>
    );
  }

};

