/* eslint-disable react/prop-types */
import React from 'react';
import { FormattedMessage } from 'react-intl';

const LoadingIndicator = (props) => {
  if (props.loadingMsg) {
    return (
      <div className='loading-indicator'>
        <div className='loading-indicator__figure' />
        <div style={{ marginTop: 60, textTransform: 'none' }}>{props.loadingMsg} </div>
      </div>
    );
  }
  return (
    <div className='loading-indicator'>
      <div className='loading-indicator__figure' />
      <FormattedMessage id='loading_indicator.label' defaultMessage='Loading...' />
    </div>
  );
};

export default LoadingIndicator;
