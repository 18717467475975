import { accessToken, eunomiaDomain } from '../initial_state';
import axios from 'axios';
import Storage from '../features/eunomia/storage';
import { EUNOMIA_APP_TERMS } from '../features/eunomia';

const unsubscribePush = (cb) => {
  const url = `https://${eunomiaDomain}/api/v1/push/subscription`;
  axios
    .delete(url, { headers: { Authorization: `Bearer ${accessToken}` }, timeout: 3000 })
    .then(()=>{})
    .catch(()=>{})
    .finally(() => cb());
};

export const logOut = () => {

  unsubscribePush(() => {
    Storage.clear().then((result) => {
      console.log(result);
      Storage.set(EUNOMIA_APP_TERMS, 'yes').then(()=>{}).catch(()=>{}).finally(()=>{
        window.location.assign('/eunomia/logout');
      });
    }).catch((reason) => {
      //eslint-disable-next-line no-console
      console.log(reason);
      window.location.assign('/eunomia/logout');
    });
  });
};
