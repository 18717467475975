import React from 'react';
import { Provider } from 'react-redux';
import axios from 'axios';
import * as registerPushNotifications from '../actions/push_notifications';
import { setupBrowserNotifications } from '../actions/notifications';
import { PersistGate } from 'redux-persist/integration/react';
import PropTypes from 'prop-types';
import configureStore, { persistor } from '../store/configureStore';
import { BrowserRouter, Route } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import UI from '../features/ui';
import { connectUserStream, connectEunomiaStream } from '../actions/streaming';
import { IntlProvider, addLocaleData } from 'react-intl';
import { getLocale } from '../locales';
import { previewState as previewMediaState } from '../features/ui/components/media_modal';
import { previewState as previewVideoState } from '../features/ui/components/video_modal';
import ErrorBoundary from '../components/error_boundary';
import { getInitialState, getMetaFromApi, setInitialState } from '../initial_state';
import { hydrateStore } from '../actions/store';
import { fetchCustomEmojis } from '../actions/custom_emojis';
import Splash from '../features/splash';
import Auth from '../features/eunomia/auth';
import { APPS_DB_KEY, ACCOUNTS_DB_KEY, APP_PREFERENCES, Storage, eunomiaInit, notFirstRun, getTheme, setTheme } from '../features/eunomia';

const { localeData, messages } = getLocale();
addLocaleData(localeData);

export const store  = configureStore();

class AppMount extends React.PureComponent {

  shouldUpdateScroll (_, { location }) {
    return location.state !== previewMediaState && location.state !== previewVideoState;
  }

  componentDidMount() {
    registerPushNotifications.checkOSXNotifications();
  }

  render () {

    return (
      <BrowserRouter basename='/eunomia'>
        <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
          <Route path='/' component={UI} />
        </ScrollContext>
      </BrowserRouter>
    );
  }

}

class AuthMount extends React.PureComponent {

  static propTypes = {
    onEnd: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.renderAuth = this.renderAuth.bind(this);
  }

  renderAuth (props) {
    return <Auth onEnd={this.props.onEnd} {...props} />;
  };

  render() {
    return (
      <BrowserRouter basename='/eunomia'>
        <Route path='/' render={this.renderAuth} />
      </BrowserRouter>
    );
  }

}

export default class Eunomia extends React.PureComponent {

  static propTypes = {
    locale: PropTypes.string.isRequired,
  };

  state = {
    ready: false,
    haveAccount: false,
  };

  constructor(props) {
    super(props);
    this.onBeforeLift = this.onBeforeLift.bind(this);
    this.onToken = this.onToken.bind(this);
    this._setState = this._setState.bind(this);
    this._validate_mastodon_account = this._validate_mastodon_account.bind(this);
  }

  onBeforeLift() {
    if (this.state.haveAccount) {
      store.dispatch(setupBrowserNotifications());
      store.dispatch(registerPushNotifications.register());
      const hydrateAction = hydrateStore(getInitialState());
      store.dispatch(hydrateAction);
      store.dispatch(fetchCustomEmojis());
      this.disconnect = store.dispatch(connectUserStream());
      store.dispatch(eunomiaInit());
      this.eunomiaDisconnect = store.dispatch(connectEunomiaStream());
    }
  }

  _setState (dbAccounts, domain, access_token, vapid_key, save = true) {
    // console.log(dbAccounts, vapid_key);
    getMetaFromApi(access_token, domain)
      .then(({ preferences, instance, account }) => {
        setInitialState(instance, preferences, account, access_token, vapid_key, dbAccounts.eunomia);
      })
      .catch(console.error)
      .finally(() => {
        if (save) {
          Storage.set(ACCOUNTS_DB_KEY, dbAccounts).then(() => {}).catch(console.error).finally(() => {
            const initialState = getInitialState();
            this.setState({ ready: true, haveAccount: initialState !== null });
          });
        } else {
          const initialState = getInitialState();
          this.setState({ ready: true, haveAccount: initialState !== null });
        }
      });
  }

  _checkTheme() {
    Storage.get(APP_PREFERENCES).then((preferences) => {
      if (preferences && preferences.theme) {
        const { theme } = preferences;
        if (theme === 'dark') {
          const existing_theme = getTheme();
          if (existing_theme === 'light') {
            setTheme('dark', false);
          }
        }
      }
    }).catch(console.warn);
  }

  _validate_mastodon_account(token, domain, cb) {
    const accountUrl = `https://${domain}/api/v1/accounts/verify_credentials`;
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` };
    axios.get(accountUrl, { headers }).then(() => cb(true)).catch(() => cb(false));
  }

  componentDidMount() {
    this._checkTheme();
    const dbAccounts = { eunomia: null, mastodon: [], active: null };
    let save = false;
    let vapidKey = undefined;
    let apps = undefined;
    notFirstRun((notFirst) => {
      if (!notFirst) {
        Storage.clear().then(() => {}).catch(console.warn).finally(() => {
          this.setState({ ready: true, haveAccount: false });
        });
      } else {
        Storage
          .get(ACCOUNTS_DB_KEY)
          .then((response) => {
            if (response && response.eunomia) {
              dbAccounts.eunomia = response.eunomia;
              save = true;
            }
            Storage
              .get(APPS_DB_KEY).then((_apps) => {
                apps = _apps;
                if (response && response.mastodon && response.mastodon.length > 0) {
                  const { active } = response;
                  if (!active || !active.access_token || !active.domain) {
                    const existing = response.mastodon[0];
                    if (existing.access_token && existing.domain) {
                      dbAccounts.active = existing;
                      save |= true;
                    }
                  } else {
                    dbAccounts.active = active;
                  }
                }
                if (dbAccounts.active !== null && dbAccounts.mastodon.length === 0) {
                  dbAccounts.mastodon.push(dbAccounts.active);
                }
              })
              .catch(console.warn)
              .finally(() => {
                if (dbAccounts.active) {
                  const { domain, access_token } = dbAccounts.active;
                  if (domain && access_token) {
                    if (apps && apps[domain] && apps[domain].vapid_key) {
                      vapidKey = apps[domain].vapid_key;
                    }
                    this._validate_mastodon_account(access_token, domain, (valid) => {
                      if (valid) {
                        this._setState(dbAccounts, domain, access_token, vapidKey, save);
                      } else {
                        dbAccounts.active = null;
                        dbAccounts.mastodon = dbAccounts.mastodon.filter(e => e.access_token !== access_token);
                        this.setState({ ready: true, haveAccount: false });
                      }
                    });
                  } else {
                    this.setState({ ready: true, haveAccount: false });
                  }
                } else {
                  this.setState({ ready: true, haveAccount: false });
                }
              });
          }).catch((e) => {
            console.warn(e);
            this.setState({ ready: true, haveAccount: false });
          });
      }
    });
  }

  onToken ({ access_token, vapid_key, domain, eunomia }) {
    if (!access_token || !domain || !eunomia) {
      this.setState({ ready: true, haveAccount: false });
    } else {
      const eunomiaEntry = {};
      eunomiaEntry[eunomia] = {};
      const dbAccounts = { eunomia: eunomiaEntry, mastodon: [], active: null };
      const newAccount = { access_token, vapid_key, domain };
      Storage.get(ACCOUNTS_DB_KEY)
        .then((accounts) => {
          if (accounts) {
            let { mastodon } = accounts;
            if (!mastodon) {
              mastodon = [];
            }
            const without = mastodon.filter(entry => entry.domain !== domain);
            without.push(newAccount);
            dbAccounts.mastodon = without;
          } else {
            dbAccounts.active = newAccount;
            dbAccounts.mastodon = [newAccount];
          }
        })
        .catch(() => {}).finally(() => {
          dbAccounts.active = newAccount;
          dbAccounts.mastodon = [newAccount];
        })
        .finally(() => {
          this._setState(dbAccounts, domain, access_token, vapid_key, true);
        });
    }
  }

  componentWillUnmount () {
    if (this.disconnect) {
      this.disconnect();
      this.disconnect = null;
    }
    if (this.eunomiaDisconnect) {
      this.eunomiaDisconnect();
      this.eunomiaDisconnect = null;
    }
  }

  render () {

    const { locale } = this.props;
    // if (this.state.ready) {
    //   if (!this.state.haveAccount &&
    //     (
    //       window.location.pathname !== '/eunomia' &&
    //       window.location.pathname !== '/eunomia/' &&
    //       window.location.pathname !== '/eunomia/welcome' &&
    //       window.location.pathname !== '/eunomia/callback'
    //     )
    //   ) {
    //     // problem with electron on first load, add a small timeout
    //     setTimeout(() => {
    //       if (window.location.pathname !== '/eunomia/welcome') {
    //         window.location.href = '/eunomia/welcome';
    //       }
    //     }, 2000);
    //     // no account: go to /eunomia/welcome
    //     return <Splash />;
    //   }
    //   if (this.state.haveAccount && window.location.pathname === '/eunomia/welcome') {
    //     // we have an account and we are on /eunomia/welcome: go to /eunomia
    //     // problem with electron on first load, add a small timeout
    //     setTimeout(() => {
    //       if (window.location.pathname === '/eunomia/welcome') {
    //         window.location.href = '/eunomia/timelines/home';
    //       }
    //     }, 2000);
    //     return <Splash />;
    //   }
    // }
    if (window.location.pathname === '/eunomia/logout') {
      setTimeout(() => {
        window.location.href = '/eunomia/welcome';
      }, 2000);
    }

    // eslint-disable-next-line no-nested-ternary
    return  this.state.ready ? (
      <IntlProvider locale={locale} messages={messages}>
        { this.state.haveAccount ? (
          <Provider store={store}>
            <PersistGate onBeforeLift={this.onBeforeLift} loading={<Splash />} persistor={persistor}>
              <ErrorBoundary>
                <AppMount />
              </ErrorBoundary>
            </PersistGate>
          </Provider>
        )  : (
          <AuthMount onEnd={this.onToken} />
        ) }
      </IntlProvider>
    ) : <Splash />;
  }

}
