import React from 'react';
import Toggle from 'react-toggle';
import { getAuthUrl, checkMastodonUrl, checkEunomiaUrl, notFirstRun, acceptedTerms, getEunomiaInstances } from './lib';
import Button from '../../../components/button';
import ModalRoot from '../../../components/modal_root';
import { terms } from './terms';
import { isValidURL, plainDomain, setBodyOverflowY } from '../lib';
import { getTheme, setTheme } from '../theme';
import logoDark from '../../../../images/logo_dark.png';
import logoLight from '../../../../images/logo_light.png';

const agreements = [
  {
    key: 'agreement1',
    title: 'I give my consent to participate in the EUNOMIA Project',
    link: '(Consent for Research and Privacy Policy)',
  },
  {
    key: 'agreement2',
    title: 'I consent to the processing of my personal data for the EUNOMIA research project in accordance with the Privacy Policy',
    link: '(Consent for Research and Privacy Policy)',
  },
  {
    key: 'agreement3',
    title: 'I confirm that I am over the age of 18',
    link: undefined,
  },
  {
    key: 'agreement4',
    title: 'I understand that there is no tolerance for objectionable content or abusive users and that my account may be blocked or even permanently deleted if abusive behavior is detected.',
    link: undefined,
  },
];

const inputValidColor = '#2b90d9';
const inputInvalidColor = '#e87487';

export default class Login extends React.PureComponent {

  state = {
    modalOpen: false,
    step: 1,
    theme: getTheme(),
    imgSrc: getTheme() === 'dark' ? logoDark : logoLight,
    mastodonUrl: '',
    eunomiaUrl: '',
    isEunomiaHosted: false,
    error: '',
    checking: false,
    focused: false,
    focusColor: inputValidColor,
    accepted: {
      agreement1: false,
      agreement2: false,
      agreement3: false,
      agreement4: false,
    },
    firstRun: true,
    ready: false,
    selectedInstance: '',
    instances: [],
  }

  constructor(props) {
    super(props);
    this.onAcceptedChange = this.onAcceptedChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onLinkClick = this.onLinkClick.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onThemeToggle = this.onThemeToggle.bind(this);
    this.stepOne = this.stepOne.bind(this);
    this.stepTwoThree = this.stepTwoThree.bind(this);
    this.stepFour = this.stepFour.bind(this);
    this.selectInstance = this.selectInstance.bind(this);
    this.checkInstance = this.checkInstance.bind(this);
  }

  componentDidMount() {
    notFirstRun((seenTerms) => {
      if (seenTerms) {
        this.setState({
          ...this.state,
          ready: true,
          step : 2,
          firstRun: false,
          accepted : {
            agreement1: true,
            agreement2: true,
            agreement3: true,
            agreement4: true,
          },
        });
      } else {
        this.setState({
          ...this.state,
          ready: true,
        });
      }
    });
    getEunomiaInstances((instances) => {
      this.setState({
        ...this.state,
        instances,
        ready: true,
        selectedInstance: instances.length > 0 ? this.state.selectedInstance: 'other',
      });
    });
  }

  onCloseModal () {/*handled by button inside the modal(explicit action) */}

  onModalClose() {
    setBodyOverflowY('scroll');
    this.setState({ modalOpen: false });
  }

  checkInstance(url) {
    this.setState({ checking: true, error: '' });
    checkMastodonUrl(url, true).then((isEunomiaHosted) => {
      const stepUp = isEunomiaHosted ? 2 : 1;
      this.setState({
        mastodonUrl: `https://${plainDomain(url)}`,
        step: this.state.step + stepUp,
        checking: false,
        isEunomiaHosted,
        eunomiaUrl: isEunomiaHosted ? `https://${plainDomain(url)}` : this.state.eunomiaUrl,
      });
    }).catch((reason) => {
      console.warn(reason);
      this.setState({ error: 'Not a valid EUNOMIA instance', checking: false });
    });
  }

  nextStep() {
    switch (this.state.step) {
    case 1:
      if (this.acceptedAll()) {
        acceptedTerms(() => {
          this.setState({ step: this.state.step + 1, checking: false });
        });
      }
      break;
    case 2:
      if (this.state.selectedInstance.startsWith('http') && this.state.instances.length > 0) {
        this.checkInstance(this.state.selectedInstance);
      } else {
        if (isValidURL(this.state.mastodonUrl)) {
          this.checkInstance(this.state.mastodonUrl);
        } else {
          this.setState({ error: 'Invalid Url', checking: false });
        }
      }
      break;
    case 3:
      if (isValidURL(this.state.eunomiaUrl)) {
        this.setState({ checking: true, error: '' });
        checkEunomiaUrl(this.state.eunomiaUrl).then(() => {
          this.setState({ step: this.state.step + 1, checking: false });
        }).catch((reason) => {
          console.warn(reason);
          this.setState({ error: 'Not a valid EUNOMIA Instance', checking: false });
        });
      } else {
        this.setState({ error: 'Invalid Url', checking: false });
      }
      break;
    case 4:
      getAuthUrl(this.state.mastodonUrl, this.state.eunomiaUrl, (url) => {
        if (url !== null) {
          window.location.href = url;
        }
      });
      // window.location.href = getAuthUrl(this.state.mastodonUrl, this.state.eunomiaUrl);
      break;
    default:
      this.setState({ error: '', checking: false });
      break;
    }
  }

  onAcceptedChange (e) {
    const agreementId = e.target.id;
    const { accepted } = this.state;
    this.setState({
      ...this.state,
      accepted: {
        ...accepted,
        [agreementId]: !accepted[agreementId],
      },
    });
  }

  onLinkClick (e) {
    e.preventDefault();
    e.stopPropagation();
    setBodyOverflowY();
    this.setState({ modalOpen: true });
  }

  onBack (e) {
    e.preventDefault();
    e.stopPropagation();
    const stepsBack = this.state.step === 4 && this.state.isEunomiaHosted ? 2 : 1;
    this.setState({ step: this.state.step - stepsBack, error: '' });
  }

  acceptedAll () {
    return !Object.values(this.state.accepted).some(value => !value);
  }

  onKeyPress (e) {
    if (e.key === 'Enter') {
      this.nextStep();
    }
  }

  onInputChange (e) {
    const value = e.target.value;
    const id = e.target.id;
    const isValid = isValidURL(value);
    const focusColor = isValid ? inputValidColor : inputInvalidColor;
    this.setState({ ...this.state, focusColor, [id]: e.target.value, error: '' });
  }

  onInputFocus () {
    this.setState({ focused: true });
  }

  onInputBlur () {
    this.setState({ focused: false });
  }
  stepOne () {
    const { accepted, checking } = this.state;
    return (
      <div>
        <label className='setting-toggle__label setting-toggle-inline__label'>
          <span>
            <a href='https://eunomia.social' rel='noreferrer' target='_blank' title='EUNOMIA'>EUNOMIA</a>
            &nbsp;is a new platform powered by Mastodon for promoting "trust" over "like" nudging social media
            users to prioritise critical engagement with online information before they react to it.
            Its development is ongoing, and by using it, you are also contributing to that end.
          </span>
        </label>
        { agreements.map((entry) => {
          const { key } = entry;
          return (
            <div className='setting-toggle setting-toggle-inline' style={{ alignItems: 'flex-start', marginTop: 20, textAlign: 'justify' }} key={`div-${key}`}>
              <Toggle id={key} checked={accepted[key]} onChange={this.onAcceptedChange} />
              <label className='setting-toggle__label setting-toggle-inline__label' htmlFor={`${key}`}>
                <span>{entry.title}{ entry.link ? <span>&nbsp;<a href='/terms' onClick={this.onLinkClick} >{entry.link}</a>.</span>: null }</span>
              </label>
            </div>
          );
        })}
        <div className='welcome-error'>{this.state.error}</div>
        <div className='account--panel__button' style={{ marginTop: 20, marginBottom: 20 }}>
          <Button block onClick={this.nextStep} disabled={checking || !this.acceptedAll()} text={'Next'} />
        </div>
      </div>
    );
  }

  selectInstance() {
    if (this.state.instances.length === 0) {
      return null;
    }
    const { step, eunomiaUrl, mastodonUrl } = this.state;
    const checkError = (selectedUrl) => {
      if (selectedUrl === '') {
        return '';
      }
      if (selectedUrl.startsWith('http')) {
        return '';
      }
      if (step === 2) {
        return isValidURL(mastodonUrl) ? '': 'Invalid URL';
      }
      if (step === 3) {
        return isValidURL(eunomiaUrl) ? '': 'Invalid URL';
      }
      return '';
    };
    return (
      <div className='fields-row__column fields-group fields-row__column-12'>
        <div className='input with_label select optional select_instance_input'>
          <div className='label_input'>
            <label
              className='select optional'
              htmlFor='auth_select_instance'
            >
              EUNOMIA Instance URL:
            </label>
            <div className='label_input__wrapper'>
              <select
                className='select optional eunomia_instance_select'
                id='auth_select_instance'
                value={this.state.selectedInstance}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(e) => this.setState({ ...this.state, selectedInstance: e.target.value, error: checkError(e.target.value) })}
              >
                <option value='' disabled>Select instance</option>
                { this.state.instances.map((entry) => (
                  <option key={`eunomia-instance-${entry}`} value={entry}>{entry}</option>
                )) }
                <option value='other'>Other</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }

  stepTwoThree() {
    const { focused, focusColor, step, eunomiaUrl, mastodonUrl, checking, instances, selectedInstance } = this.state;
    const id = step === 2 ? 'mastodonUrl': 'eunomiaUrl';
    const value = step === 2 ? mastodonUrl : eunomiaUrl;
    const nextDisabled = checking || ( (selectedInstance === '' || selectedInstance === 'other') && (!isValidURL(value) || value.length === 0));
    const placeholder = 'https://one.eunomia.instance';
    const label = instances.length > 0 ? '' : 'EUNOMIA Instance URL:';
    const labelStyle = instances.length > 0 ? { height: 16, display: 'inherit' } : {};
    return (
      <div className='fields-row'>
        <div style={{ textAlign: 'end' }}>
          <a className='welcome-back-button' href='/back' onClick={this.onBack}>terms</a>
        </div>
        { this.selectInstance() }
        { selectedInstance === 'other' &&
        <div className='fields-row__column fields-group fields-row__column-12'>
          <div className='input with_label string optional'>
            <div className='label_input'>
              <label className='string optional' htmlFor={id} style={labelStyle} >&nbsp;{label}</label>
              <div className='label_input__wrapper'>
                <input
                  className='welcome-input'
                  type='url'
                  style={{
                    borderColor: focused? focusColor: 'transparent',
                    borderWidth: 2,
                    padding: 5,
                    fontSize: 16,
                  }}
                  placeholder={placeholder}
                  id={id}
                  value={value}
                  onChange={this.onInputChange}
                  onFocus={this.onInputFocus}
                  onBlur={this.onInputBlur}
                  onKeyPress={this.onKeyPress}
                  autoCorrect='off'
                  autoComplete='off'
                  autoCapitalize='off'
                />
              </div>
            </div>
          </div>
        </div> }
        <div className='welcome-error'>{this.state.error}</div>
        <div className='account--panel__button' style={{ marginTop: 10, marginBottom: 20 }}>
          <Button block onClick={this.nextStep} disabled={nextDisabled} text={'Next'} />
        </div>
      </div>
    );
  }

  stepFour() {
    return (
      <div>
        <div style={{ textAlign: 'end' }}>
          <a className='welcome-back-button' href='/back' onClick={this.onBack}>back</a>
        </div>
        <div className='welcome-last-step'>You will next be asked to authorize Digital Companion having access to your account.</div>
        <div className='welcome-error'>{this.state.error}</div>
        <div className='account--panel__button' style={{ marginTop: 10, marginBottom: 20 }}>
          <Button block onClick={this.nextStep} disabled={this.state.checking} text={'Join!'} />
        </div>
      </div>
    );
  }

  onThemeToggle () {
    const theme = getTheme();
    const newTheme = theme === 'dark' ? 'light': 'dark';
    setTheme(newTheme, true, () => {
      this.setState({ theme: getTheme(), imgSrc: newTheme === 'dark' ? logoDark: logoLight });
    });
  }

  render() {
    const { ready, step, theme, imgSrc } = this.state;
    if (!ready) {
      return null;
    }
    const buttonText = theme === 'dark' ? 'light theme' : 'dark theme';
    return (
      <div className='rich-formatting settings-wrapper auth-welcome content'>
        <div className='welcome-top' >
          <div>
            <Button onClick={this.onThemeToggle} text={buttonText} className='welcome-button' />
          </div>
          <div>
            <img src={imgSrc} className='welcome-logo' alt='EUNOMIA Logo' />
          </div>
        </div>
        <div className='welcome-main'>
          {/* eslint-disable-next-line no-nested-ternary */}
          { step === 1 ? this.stepOne() : step === 4 ? this.stepFour() : this.stepTwoThree() }
        </div>
        <ModalRoot onClose={this.onCloseModal}>
          {
            this.state.modalOpen &&
            <div className='welcome-modal'>
              { terms() }
              <div className='account--panel__button' style={{ marginTop: 20, marginBottom: 10, textAlign: 'end' }}>
                <Button onClick={this.onModalClose} text={'CLOSE'} className='welcome-button' />
              </div>
            </div>
          }
        </ModalRoot>
      </div>
    );
  }

}
