import React from 'react';
import eunomiaSvg from '../../../../images/icon_eunomia.svg';

export default class EunomiaIcon extends React.PureComponent {

  render() {
    return <img className='fa-fw svg-icon' src={eunomiaSvg} alt='EUNOMIA' />;
  }

}

export const eunomiaIcon = () => <EunomiaIcon />;
