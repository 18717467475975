import React from 'react';
import PropTypes from 'prop-types';
import {
  storeTokenAndVapidKey,
  getAppToken,
  decodeCallback,
} from './lib';
import { plainDomain } from '../lib';
import { Redirect } from 'react-router-dom';

export default class Callback extends React.PureComponent {

  state = {
    code: '',
    domain: '',
  }

  static propTypes = {
    onEnd: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const haveEdoc = urlParams.has('edoc');
    const haveCode = urlParams.has('code');
    if (!haveEdoc || !haveCode) {
      this.setState({ code: null, domain: null });
    } else {
      const edoc = urlParams.get('edoc');
      const code = urlParams.get('code');
      const decoded = decodeCallback(edoc);
      const decodedParams = new URLSearchParams(decoded);
      const _domain = decodedParams.get('domain');
      const _eunomia = decodedParams.get('eunomia');
      const domain = plainDomain(_domain);
      const eunomia = plainDomain(_eunomia);
      getAppToken(domain, eunomia, code, (data) => {
        if (data !== null && data.access_token && data.vapid_key) {
          storeTokenAndVapidKey(domain, data.access_token, data.vapid_key).then((dbData) => {
            if (dbData && dbData.access_token) {
              this.props.onEnd({ access_token: data.access_token, vapid_key: data.vapid_key, domain, eunomia });
            } else {
              this.setState({ code: null, domain: null });
            }
          }).catch((e) => {
            // eslint-disable-next-line no-console
            console.warn(e);
            this.setState({ code: null, domain: null });
          });
        } else {
          this.setState({ code: null, domain: null });
        }
      });
    }
  }

  render() {
    return(this.state.domain === null || this.state.code === null ) ? (
      <Redirect to='/welcome' exact />
    ) : (
      <div />
    );
  }

}
