import { eunomiaDomain, myId } from '../../../initial_state';
import axios from 'axios';
import { getEunomiaHeaders } from '../lib';
import { getUserPostVotes } from './vote';
import { fetchCascade } from './cascade';
import {
  EUNOMIA_POST_IMPORT,
  EUNOMIA_POSTS_IMPORT,
} from '../constants';

export function importEunomiaPost(post) {
  return { type: EUNOMIA_POST_IMPORT, post };
}

export function importEunomiaPosts(posts) {
  return { type: EUNOMIA_POSTS_IMPORT, posts };
}


export const getPostRequest = (url, status, headers, dispatch) => {
  axios.get(url, { headers, timeout: 30000 }).then(({ data }) => {
    if (data && data.source_id && data.source_url ) {
      getUserPostVotes(myId, data).then((post) => {
        post.original_post = status;
        dispatch(importEunomiaPost(post));
        if (post.cascade_id) {
          fetchCascade(headers, post.cascade_id, dispatch);
        }
      }).catch((reason) => {
        // eslint-disable-next-line no-console
        console.log(reason);
        data.voted = false;
        data.trusted = null;
        dispatch(importEunomiaPost(data));
      });
    }
  }).catch(() => {
  });
};

export const getEunomiaPost = (status) => (dispatch) => {
  try {
    let _status = status;
    if (status.get('reblog', null) !== null && typeof status.get('reblog') === 'object') {
      _status = status.get('reblog');
    }
    const sourceUrl = _status.get('url', null);
    if (sourceUrl) {
      const sourceId = _status.get('id');
      const jsStatus = _status.toJS();
      const url = `https://${eunomiaDomain}/eunomia/api/mastodon/posts/${sourceId}`;
      getEunomiaHeaders().then((headers) => {
        getPostRequest(url, jsStatus, headers, dispatch);
      }).catch(() => {
        setTimeout(() => {
          getEunomiaHeaders().then((headers) => {
            getPostRequest(url, jsStatus, headers, dispatch);
          }).catch(()=>{
            setTimeout(() => {
              getEunomiaHeaders().then((headers) => {
                getPostRequest(url, jsStatus, headers, dispatch);
              }).catch(() => {});
            }, 3000);
          });
        }, 3000);
      });
    }
  } catch {}
};

export const onStatusAction = (status, action) => () => {
  try {
    let _status = status;
    if (status.get('reblog', null) !== null && typeof status.get('reblog') === 'object') {
      _status = status.get('reblog');
    }
    const sourceId = _status.get('id');
    const url = `https://${eunomiaDomain}/eunomia/api/mastodon/posts/${sourceId}`;
    getEunomiaHeaders().then((headers) => {
      if (action === 'delete') {
        axios.delete(url, { headers, timeout: 5000 }).then(()=>{}).catch(()=>{});
      } else {
        axios.patch(url, { action }, { headers, timeout: 5000 }).then(()=>{}).catch(()=>{});
      }
    }).catch(() => {});
  } catch {}
};
