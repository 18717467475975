import api from '../../api';
import { decode as decodeBase64 } from '../../utils/base64';
import { pushNotificationsSetting } from '../../settings';
import { setBrowserSupport, setSubscription, clearSubscription } from './setter';
import { applicationServerKey, me, accessToken, domainName, eunomiaDomain } from '../../initial_state';
import { db } from '../../features/eunomia';
import axios from 'axios';

const safariWebPushId =  'web.gr.uniwa.eee.consert.eunomia';
// const pushRegistrationsEndpoint = `${window.location.protocol}//${window.location.host}/eunomia/api`;
const registrationDomain = eunomiaDomain ?? domainName ?? window.location.host;
const pushRegistrationsEndpoint = `https://${registrationDomain}/eunomia/api`;

// Taken from https://www.npmjs.com/package/web-push
const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  return decodeBase64(base64);
};

const getApplicationServerKey = () => applicationServerKey;

const getRegistration = () => navigator.serviceWorker.ready;

const getPushSubscription = (registration) =>
  registration.pushManager.getSubscription()
    .then(subscription => ({ registration, subscription }));

const subscribe = (registration) =>
  registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(getApplicationServerKey()),
  });

const unsubscribe = ({ registration, subscription }) =>
  subscription ? subscription.unsubscribe().then(() => registration) : registration;

const sendSubscriptionToBackend = (subscription) => {
  const params = { subscription };
  const headers = { Authorization: `Bearer ${accessToken}` };
  if (me) {
    const data = pushNotificationsSetting.get(me);
    if (data) {
      params.data = data;
    }
  }
  if (!params.data || !params.data.alerts || (params && params.data && params.data.alerts && Object.keys(params.data.alerts).length === 0)) {
    params.data = {
      alerts: {
        follow: true,
        follow_request: true,
        favourite: true,
        mention: true,
        poll: true,
        status: true,
      },
    };
    pushNotificationsSetting.set(me, params.data);
  }

  return api().post('/api/v1/push/subscription', params, { headers }).then(response => response.data);
};

// Last one checks for payload support: https://web-push-book.gauntface.com/chapter-06/01-non-standards-browsers/#no-payload
const supportsPushNotifications = ('serviceWorker' in navigator && 'PushManager' in window && 'getKey' in PushSubscription.prototype);


const checkSafariNotifications = (cb) => {
  if ('safari' in window && 'pushNotification' in window.safari) {
    let permissionData = window.safari.pushNotification.permission(safariWebPushId);
    if (permissionData && permissionData.permission === 'granted' && permissionData.deviceToken) {
      cb(permissionData.deviceToken);
    } else {
      cb(null);
    }
  }
};

const checkSafariPermission = function (permissionData) {
  if (permissionData) {
    if (permissionData.permission === 'default') {
      addPushButton();
    } else if (permissionData.permission === 'denied') {
      // The user said no.
      removePushButton();
    } else if (permissionData.permission === 'granted') {
      // The web service URL is a valid push provider, and the user said yes.
      // permissionData.deviceToken is now available to use.
      removePushButton();
      registerIfNeeded(`push/notify/${permissionData.deviceToken}`);
    }
  }
};

const registerIfNeeded = (path) => {
  // TODO: also check (and allow edit) the alert preferences
  if (accessToken) {
    const endpoint =`${pushRegistrationsEndpoint}/${path}`;
    const subscriptionEndpoint = `${pushRegistrationsEndpoint}/push/subscribe`;
    axios.get(`https://${domainName}/api/v1/push/subscription`, { headers: { Authorization: `Bearer ${accessToken}` } }).then(({ data }) => {
      if (data && data.endpoint && data.endpoint !== endpoint) {
        axios.post(subscriptionEndpoint, {
          access_token: accessToken,
          mastodon_domain: domainName,
          endpoint,
        }, { headers: { 'Content-Type': 'application/json' } })
          .then(() => {
            // eslint-disable-next-line no-console
          }).catch((reason) => console.log(reason));
      }
      // eslint-disable-next-line no-console
    }).catch(() => {
      axios.post(subscriptionEndpoint, {
        access_token: accessToken,
        mastodon_domain: domainName,
        endpoint,
      }, { headers: { 'Content-Type': 'application/json' } })
        .then(() => {
          // eslint-disable-next-line no-console
        }).catch((reason) => console.log(reason));
    });
  }
};

export const checkOSXNotifications = () => {
  const deviceTokenPlaceholder = window.document.getElementById('deviceToken');
  if (deviceTokenPlaceholder && 'iosDeviceToken' in window) {
    deviceTokenPlaceholder.addEventListener('change', (event) => {
      const deviceToken = event.target.value;
      if (deviceToken && deviceToken.length > 5 && deviceToken !== window.iosDeviceToken) {
        window.iosDeviceToken = deviceToken;
        registerIfNeeded(`push/ios/notify/${window.iosDeviceToken}`);
      }
    });
  }
  checkSafariNotifications((deviceToken) => {
    if (deviceToken && accessToken) {
      registerIfNeeded(`push/notify/${deviceToken}`);
    }
  });
  if (window.iosDeviceToken && window.iosDeviceToken.length > 5 && accessToken) {
    registerIfNeeded(`push/ios/notify/${window.iosDeviceToken}`);
  }
};

const pushButtonHandler = () => {
  if ('safari' in window && 'pushNotification' in window.safari) {
    window.safari.pushNotification.requestPermission(
      `${pushRegistrationsEndpoint}/push`,
      safariWebPushId,
      { 'access_token': accessToken, 'mastodon_domain': domainName },
      checkSafariPermission,
    );
  } else {
    const Notification = window.Notification || window.mozNotification || window.webkitNotification;
    Notification.requestPermission().then(() => {}).catch(()=>{});
  }
  // it seems in some cases, the button does not get removed on change, lets check once more after a while
  if (document.getElementById('push-permissions-button')) {
    setTimeout(() => {
      if (navigator && 'permissions' in navigator) {
        navigator.permissions.query({ name: 'notifications' }).then(function (permission) {
          if (['granted', 'denied'].includes(permission.state)) {
            removePushButton();
          }
          permission.onchange = function () {
            if (['granted', 'denied'].includes(permission.state)) {
              removePushButton();
            }
          };
        }).catch(() => {});
      }
    }, 5000);
  }
};

const fabListener = (fab, add = true) => {
  if (fab) {
    if (add) {
      fab.addEventListener('click', pushButtonHandler);
    } else {
      fab.removeEventListener('click', pushButtonHandler);
    }
  }
};

const addPushButton = () => {
  const fabId = 'push-permissions-button';
  if (!document.getElementById(fabId)) {
    const fab = document.createElement('button');
    fab.id = fabId;
    fab.style.cssText = 'display: flex;align-items:center;justify-content:center;position: fixed;bottom: 30px; right: 10px;background-color: rgb(217, 225, 232);border: none;border-radius: 25px;width: 50px;height: 50px;';
    fab.innerHTML = '<div aria-label={"Push Notifications"}><i class="fa fa-bell" style="font-size:25px;color:#55449a" aria-hidden="true"></i></div>';
    document.body.appendChild(fab);
    fabListener(fab, true);
  } else {
    fabListener(document.getElementById(fabId), true);
  }
};

const removePushButton = () => {
  const fabId = 'push-permissions-button';
  if (document.getElementById(fabId)) {
    fabListener(document.getElementById(fabId), false);
    document.body.removeChild(document.getElementById(fabId));
  }
};
if (navigator && 'serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.periodicSync.getTags().then(tags => {
      if (!tags.includes('fetch-updates')) {
        registration.periodicSync.register('fetch-updates', {
          minInterval: 24 * 60 * 60 * 1000,
        }).then(() => {
        }).catch(() => {
        });
      }
    }).catch(() => {
    });
  }).catch(() => {
  });
}
if (navigator && 'permissions' in navigator) {
  navigator.permissions.query({ name: 'notifications' }).then(function (permission) {
    if (!['granted', 'denied'].includes(permission.state)) {
      addPushButton();
    } else {
      removePushButton();
    }
    permission.onchange = function () {
      if (!['granted', 'denied'].includes(permission.state)) {
        addPushButton();
      } else {
        removePushButton();
      }
    };
  }).catch(() => {});
}

const checkPushPermission = () => {
  let permission = '';
  if ('safari' in window && 'pushNotification' in window.safari) {
    permission =  window.safari.pushNotification.permission(safariWebPushId).permission;
  } else {
    const Notification = window.Notification || window.mozNotification || window.webkitNotification;
    if (Notification && 'permission' in Notification) {
      permission = Notification.permission;
    }
  }
  if (permission && permission === 'default') {
    addPushButton();
  }
};

export function register () {
  return (dispatch, getState) => {
    dispatch(setBrowserSupport(supportsPushNotifications));
    if (supportsPushNotifications) {
      if (!getApplicationServerKey()) {
        console.error('The VAPID public key is not set. You will not be able to receive Web Push Notifications.');
        return;
      }

      getRegistration()
        .then(getPushSubscription)
        .then(({ registration, subscription }) => {
          if (subscription !== null) {
            // We have a subscription, check if it is still valid
            const currentServerKey = (new Uint8Array(subscription.options.applicationServerKey)).toString();
            const subscriptionServerKey = urlBase64ToUint8Array(getApplicationServerKey()).toString();
            const serverEndpoint = getState().getIn(['push_notifications', 'subscription', 'endpoint']);
            // const serverEndpoint = initialState.push_notifications.push_subscription.endpoint;
            // If the VAPID public key did not change and the endpoint corresponds
            // to the endpoint saved in the backend, the subscription is valid
            if (subscriptionServerKey === currentServerKey && subscription.endpoint === serverEndpoint) {
              // console.log(subscription.toJSON());
              return subscription;
            } else {
              return unsubscribe({ registration, subscription }).then(subscribe).then(
                subscription => sendSubscriptionToBackend(subscription));
            }
          }

          // No subscription, try to subscribe
          return subscribe(registration).then(
            subscription => sendSubscriptionToBackend(subscription));
        })
        .then(subscription => {
          // If we got a PushSubscription (and not a subscription object from the backend)
          // it means that the backend subscription is valid (and was set during hydration)
          if (!(subscription instanceof PushSubscription)) {
            db.save('SAVED_PUSH_CONFIG', subscription).then(() => {}).catch(()=>{});
            dispatch(setSubscription(subscription));
            if (me) {
              pushNotificationsSetting.set(me, { alerts: subscription.alerts });
            }
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          // console.log('error', error.code, error.name);
          if (error.code === 20 && error.name === 'AbortError') {
            console.warn('Your browser supports Web Push Notifications, but does not seem to implement the VAPID protocol.');
          } else if (error.code === 5 && error.name === 'InvalidCharacterError') {
            console.error('The VAPID public key seems to be invalid:', getApplicationServerKey());
          } else if (error.name === 'NotAllowedError') {
            checkPushPermission();
          }
          db.clear('SAVED_PUSH_CONFIG').then(() => {}).catch(()=>{});
          // Clear alerts and hide UI settings
          dispatch(clearSubscription());
          if (me) {
            pushNotificationsSetting.remove(me);
          }

          return getRegistration()
            .then(getPushSubscription)
            .then(unsubscribe);
        })
        .catch((e) => console.warn(e));
    } else {
      checkPushPermission();
      checkOSXNotifications();
    }
  };
}

export function saveSettings() {
  return (_, getState) => {
    const state = getState().get('push_notifications');
    // const subscription = state.get('subscription');
    const alerts = state.get('alerts');
    const data = { alerts };

    api(getState).put('/api/v1/push/subscription', {
      data,
    }).then(() => {
      if (me) {
        pushNotificationsSetting.set(me, data);
      }
    }).catch(console.warn);
  };
}
