/* eslint-disable no-console, consistent-return */
'use strict';

const axios = require('axios');
const crypto = require('./cryptography.js');
const level = require('level');

let services = (function() {

  // relevant paths
  const NODE_INFO_PATH = '/node/info';
  const DISCOVERY_PATH = '/discovery';
  const STORAGE_PATH = '/objects';
  const VOTING_PATH = '/voting';

  // API paths
  const VOTER_PATH = '/voter';

  // services' urls
  let urls = {};

  // create or open database files
  const storage = level('./storage', { 'valueEncoding': 'json' });

  // dictionary of methods exported by this module
  let calls = {};

  // split methods by category
  let node = {};
  let discovery = {};
  let users = {};
  let db = {};
  let response = {};

  //------------------------------------------------------------------------------------------
  // NODE INFORMATION
  //------------------------------------------------------------------------------------------

  /**
	 * Extracts EUNOMIA Node general information from the node
	 * information service, including Voting Server ID.
	 *
	 * @param endpoint - Endpoint of the EUNOMIA node
	 * @returns {Promise<Object>} Object with Node Information
	 */
  node.getInfo = function(endpoint) {
    return new Promise((resolve, reject) => {
      let url;
      try {
        // parse url and set parameters
        url = new URL(endpoint);
        // console.log('getInfo() pathname'+url.pathname);
        url.pathname = url.pathname + NODE_INFO_PATH;
        // console.log('getInfo() final URL:'+JSON.stringify(url));
      } catch (e) {
        // returns to stop execution
        return reject(response.setError(e.message, calls.service.CLIENT));
      }

      // make request
      axios.get(url.toString()).then(response => {
        // check if voting service id exists
        if ('votingServiceId' in response.data)
          resolve(response.data);

        // reject if it does not exist
        reject(response.setError('EUNOMIA Node Information is invalid', calls.service.DISCOVERY));
      }).catch(error => {
        reject(response.setError(error, calls.service.DISCOVERY));
      });
    });
  };

  /**
	 * Sets endpoints for storage and discovery services.
	 *
	 * @param {String} endpoint - Endpoint of the EUNOMIA node (with
	 * base url and port)
	 * @return {Promise<Object>} Object with both URLs
	 */
  node.configure = function(endpoint) {
    return new Promise((resolve, reject) => {
      try {
        // parse endpoint and set url
        let url = new URL(endpoint);

        //set default path
        urls.storagePath = url.pathname;

        // set discovery
        url.pathname = urls.storagePath + DISCOVERY_PATH;
        urls.discovery = url.toString();

        // set storage
        url.pathname = urls.storagePath + STORAGE_PATH;
        urls.storage = url.toString();

        // resolve promise
        resolve(urls);
      } catch (e) {
        reject(response.setError(e.message, calls.service.CLIENT));
      }
    });
  };

  //------------------------------------------------------------------------------------------
  // DISCOVERY SERVICE
  //------------------------------------------------------------------------------------------

  /**
	 * Uses discovery service to gather info on voting servers (this function is
	 * only called inside the services file).
	 *
	 * @param {String} token - EUNOMIA token for the user
	 * @param {String} id - ID of Voting Server OR null to return every Voting Server
	 * @returns {Promise<Array>} List of Voting Servers with format
	 * [{manager: <String>, tallier: <String>, anonymizer: <String>, live: <Boolean>,
	 * id: <String>}, ...]
	**/
  function searchServices(token, id) {
    if (urls.discovery === undefined)
      return Promise.reject(response.setError('No endpoint available for discovery service', calls.service.CLIENT));

    // build url
    let url = urls.discovery + `?access_token=${token}&property=type&comp=eq&value=voting`;

    // update url (if necessary)
    if (id !== null) url += `&property=id&comp=eq&value=${id}`;

    // make request asynchronously
    return new Promise((resolve, reject) => {
      axios.get(url).then(response => {
        // get list of voting servers
        let servers = response.data.data.entities;

        // check response
        if (servers !== undefined && servers.length < 1)
          reject(response.setError('No voting servers available' + (id !== null ? ` with ID ${id}` : ''), calls.service.CLIENT));

        // build services array
        let res = [];
        servers.forEach(server => {
          // create server and add to array
          let s = server.properties;
          s.id = server.id;
          res.push(s);
        });

        // return services
        resolve(res);
      }).catch(error => {
        // reject promise with error
        reject(response.setError(error, calls.service.DISCOVERY));
      });
    });
  }

  /**
	 * Calls the discovery service to get credentials on the voting server
	 * with the specified identifier, whether it is running or not.
	 *
	 * @param {String} endpoint - Endpoint of the voting server (to be saved)
	 * @param {String} token - Authentication token of EUNOMIA user
	 * @param {String} id - ID of voting service
	 * @returns {Promise<Object>} Dictionary with voting service cryptographic information
	**/
  discovery.getVotingServer = function(endpoint, token, id) {
    // get voting server by id
    return new Promise((resolve, reject) => {
      searchServices(token, id)
        .then(servers => {
          // get first server
          let server = servers[0];

          // check if server exists and is live (but save it anyway)
          if ('live' in server && server.live === 'false')
            reject(calls.response.setError(`Voting Server with ID ${id} is not active`, services.service.CLIENT));

          // delete unnecessary keys (if they exist)
          delete server.live;

          // update voting endpoint
          let url = new URL(endpoint);
          url.pathname = url.pathname + VOTING_PATH;
          server.endpoint = url.toString();

          //add node endpoint
          let nodeUrl = new URL(urls.discovery);
          server.node = `https://${nodeUrl.host}`+urls.storagePath;

          // save and resolve
          db.save(id, server);
          resolve(server);
        }).catch(e => reject(e));
    });
  };

  //------------------------------------------------------------------------------------------
  // USERS
  //------------------------------------------------------------------------------------------

  /**
	 * Registers a user as a voter within the EUNOMIA system. Generates
	 * a new key pair for the voter and sends the public key along
	 * with the encrypted private key to the voting server.
	 *
	 * @param {String} serverId - Identifier of the voting server
	 * @param {String} token - EUNOMIA token for the user
	 * @param {String} userId - EUNOMIA user ID
	 * @param {String} password - Password or secret used by the user to
	 * encrypt the private key (using PBKDF)
	 * @return {Promise<Object>} Object with the new user
	 */
  users.register = function(serverId, token, userId, password) {
    // generate keys
    let keys = crypto.ecc.generateKeyPair();
    let publicKey = crypto.ecc.compress(keys.getPublic());
    // let privateKey = keys.getPrivate().toBuffer().toString('base64');
    let privateKey = keys.getPrivate().toArrayLike(Buffer).toString('base64');
    // encrypt private key
    let pbk = crypto.utils.pbkdf(password, null);
    let enc = crypto.aes.encrypt(privateKey, pbk.key);

    // append salt
    let encPrivate = `${enc.data}:${enc.iv}:${pbk.salt}`;

    // build request body
    let body = {
      'public_key': publicKey,
      'private_key': encPrivate,
    };

    // get server locally
    return db.get(serverId).then(server => {
      // check if server exists
      if (server === null)
        return Promise.reject(response.setError('Voting server information is not available for registration',
          calls.service.CLIENT));

      // build url
      let url = server.endpoint + `${VOTER_PATH}?user_id=${userId}&access_token=${token}`;

      // make registration call asynchronously
      return axios.post(url, body, {}).then(response => {
        // verify signature
        if (!calls.response.verify(server.manager, response))
          return Promise.reject(calls.response.setError('Manager signature is invalid',
            services.service.VOTING));

        // save user
        let user = {
          'serverId': serverId,
          'ballots': {},
          'pk': publicKey,
          'sk': privateKey,
        };
        return db.save(userId, user);
      }).catch(error => {
        // console.log(3, error);
        return Promise.reject(response.setError(error, calls.service.VOTING));
      });
    });
  };

  /**
	 * Retrieves the credentials for a previously registered voter,
	 * then rebuilds the key derived from the password to decrypt
	 * the private key.
	 *
	 * @param {String} serverId - Identifier of the voting server
	 * @param {String} token - EUNOMIA token for the user
	 * @param {String} userId - EUNOMIA user ID
	 * @param {String} password - Password or secret used by the user to
	 * encrypt the private key (using PBKDF)
	 * @return {Promise<Object>} Object with the user
	 */
  users.login = function(serverId, token, userId, password) {
    // get server locally
    return db.get(serverId).then(server => {
      // check if server exists
      if (server === null)
        return Promise.reject(response.setError('Voting server information is not available for login',
          calls.service.CLIENT));

      // build url
      let url = server.endpoint + `${VOTER_PATH}?user_id=${userId}&access_token=${token}`;

      // make login call asynchronously
      return axios.get(url).then(response => {
        // verify signature
        if (!calls.response.verify(server.manager, response))
          return Promise.reject(calls.response.setError('Manager signature is invalid',
            services.service.VOTING));

        // get parameters
        let data = response.data.data.key.split(':');
        let key = data[0];
        let iv = data[1];
        let salt = data[2];
        // decrypt private key
        let pbk = crypto.utils.pbkdf(password, salt);
        let privateKey = crypto.aes.decrypt(key, pbk.key, iv);
        if (privateKey === null)
          return Promise.reject(calls.response.setError('Password is incorrect', calls.service.CLIENT));

        // resolve user
        let user = {
          'serverId': serverId,
          'ballots': {},
          'pk': crypto.ecc.keyFromPrivate(privateKey),
          'sk': privateKey,
        };
        return db.save(userId, user);
      }).catch(error => {
        return Promise.reject(response.setError(error, calls.service.VOTING));
      });
    });
  };

  /**
	 * Requests the public keys of the voters registered in the
	 * system to build a new proof and subsequently vote.
	 *
	 * @param {String} token - EUNOMIA token for the user
	 * @param {number} size - Maximum number of keys to be returned
	 * @param {String} voterKey - Public key of the voter
	 * @return {Promise<Array<String>>} Array with the public keys
	 * of the voters
	 */
  users.getKeys = function(token, size, voterKey) {
    if (urls.storage === undefined)
      return Promise.reject(response.setError('No endpoint available for storage service', calls.service.CLIENT));

    // build url (all voters' keys expect for this user)
    let url = urls.storage + `?access_token=${token}&property=type&comp=eq&value=voterKey`;
    url += `&property=properties.public_key&comp=ne&value=${voterKey}`;

    // request keys asynchronously
    return new Promise((resolve, reject) => {

      axios.get(url, {
        validateStatus: function (status) {
          return (status >= 200 && status < 300)||status === 404; // default
        } },
      ).then(response => {

        // get data
        let keys = response.data.data;
        let res = [];

        // append randomly to result
        // or simply shuffle
        // console.log('GETTING DATA 1...');
        for (let i = 0; i < size; i++) {
          if (!keys||keys.length === 0) break;

          // generate random index
          let idx = Math.floor(Math.random() * keys.length);

          // append to res and remove from keys
          res.push(keys[idx].properties.public_key);
          keys.splice(idx, 1);
        }
        // resolve list of keys
        // console.log('user.getKeys(): Response from server ok resolved with:'+JSON.stringify(res));
        resolve(res);
      }).catch(error => {
        // console.log('Checking that the voter key doesnt exist.');
        reject(response.setError(error, calls.service.VOTING));
      });
    });
  };

  //------------------------------------------------------------------------------------------
  // ANONYMITY/MIXING
  //------------------------------------------------------------------------------------------

  /**
	 * Calls the discovery service to get services and builds a mix
	 * network of anonymizers with corresponding IDs and keys.
	 *
	 * @param {String} token - EUNOMIA token for the user
	 * @param {number} size - Size of mix network (number of anonymizers)
	 * @param {String} firstId - Identifier of the first voting server of the mix
	 * @returns {Promise<Array>} List of <size> anonymizers [{id: <id>, key: <key>}, ...]
	 * or error
	**/
  function buildMix(token, size, firstId) {
    // get list of voting services
    return new Promise((resolve, reject) => {
      searchServices(token, null).then(servers => {
        // define first anonymizer
        let firstAnonymizer = { 'id': firstId };

        // filter anonymizers (offline, first)
        servers = servers.filter(s => {
          if (s.id === firstId) firstAnonymizer.certificate = s.anonymizer;
          return s.live && s.id !== firstId;
        });

        // check size
        if (servers.length < size - 1){
          // console.log('anonymization servers size:'+servers.length);
          reject(response.setError('Not enough anonymizers available to build mix network',
            services.service.CLIENT));
        }

        // declare mix network
        let anonymizers = [];

        // add anonymizers to array
        for (let i = 0; i < size - 1; i++) {
          // random index
          let index = Math.floor(Math.random() * servers.length);
          let anon = {
            'id': servers[index].id,
            'certificate': servers[index].anonymizer,
          };

          // append to anonymizers and remove from servers
          anonymizers.push(anon);
          servers.splice(index, 1);
        }

        // add first anonymizer
        anonymizers.push(firstAnonymizer);

        // return array of anonymizers
        resolve(anonymizers);
      }).catch(e => reject(e));
    });
  }

  /**
	 * Builds an anonymization circuit and encrypts and prepares the data
	 * to go through the mix network.
	 *
	 * @param {String} accessToken - EUNOMIA token used to get anonymizer information
	 * @param {number} mixSize - Number of mix servers that will make up the mix network
	 * @param {String} serverId - Identifier of the voting server the user is in contact with
	 * @param {Object} body - Message being encrypted
	 * @returns {Promise<Object>} Data encrypted and prepared
	 */
  calls.anonymize = function(accessToken, mixSize, serverId, body) {
    // build mix and encrypt data
    return buildMix(accessToken, mixSize, serverId).then(anonymizers => {
      // encrypt (onion)
      anonymizers.forEach((anon, index) => {
        // handle keys
        let rsaKey = crypto.rsa.keyFromCertificate(anon.certificate);
        let aesKey = crypto.aes.generateKey();

        // encrypt body with symmetric key
        let encryptedData = crypto.aes.encrypt(body, aesKey);
        if (encryptedData.data === null)
          return Promise.reject(response.setError('Could not encrypt data',
            services.service.CLIENT));

        // encrypt info with anonymizer key
        let info = {
          'key': aesKey,
          'iv': encryptedData.iv,
          'next': (anonymizers[index - 1] !== undefined) ? anonymizers[index - 1].id : null,
        };
        let infoEncrypted = crypto.rsa.encrypt(info, rsaKey);
        if (infoEncrypted.data === null)
          return Promise.reject(response.setError('Could not encrypt data',
            services.service.CLIENT));

        // update body
        body = {
          'info': infoEncrypted,
          'data': encryptedData.data,
        };
      });

      // return encrypted data
      return body;
    });
  };

  //------------------------------------------------------------------------------------------
  // STORAGE (LEVELDB)
  //------------------------------------------------------------------------------------------

  /**
	 * Gets data from the LevelDB instance.
	 *
	 * @param {String} key - Storage key matching the desired object
	 * @returns {Promise<Object>} Resolves object extracted from the database or null if
	 * it does not exist and rejects if there is an error different than NotFound
	**/
  db.get = function(key) {
    return new Promise((resolve, reject) => {
      storage.get(key, function (err, value) {
        if (err)
          if (err.type === 'NotFoundError')
            resolve(null);
          else
            reject(response.setError('Local database search failed', calls.service.CLIENT));
        resolve(value);
      });
    });
  };

  /**
	 * Saves data to the current LevelDB instance.
	 *
	 * @param {String} key - Key to identify the object in the database
	 * @param {Object} object - Data to be saved associated with the key
	 * @returns {Promise<Object>} State of the put operation (object or error)
	 **/
  db.save = function(key, object) {
    return new Promise((resolve, reject) => {
      storage.put(key, object, function(err) {
        if (err)
          reject(response.setError('Local database save operation failed', calls.service.CLIENT));
        resolve(object);
      });
    });
  };

  /**
	 * Deletes a number of keys and corresponding objects from the LevelDB
	 * local database, or clears the local storage if no arguments are
	 * passed to the function.
	 *
	 * @param {Array<String>} keys - Variable number of keys to be deleted from local storage
	 * @returns {Promise<Object>} State of the delete/clear operation
	 */
  db.clear = function(...keys) {
    return new Promise((resolve, reject) => {
      if (keys.length === 0) {
        storage.clear(function (err) {
          if (err)
            reject(response.setError('Clearing of local database failed', calls.service.CLIENT));
          resolve('Local database successfully cleared');
        });
      } else {
        let errors = [];
        for (let i = 0; i < keys.length; i++) {
          // delete keys from function arguments
          storage.del(keys[i], function (err) {
            if (err) errors.push(keys[i]);
          });
        }

        // resolve if no error found when deleting objects
        if (errors.length === 0)
          resolve('The selected keys were deleted successfully');
        else
          reject(response.setError(`Keys ${errors} failed to be deleted from the local database`, calls.service.CLIENT));
      }
    });
  };

  //------------------------------------------------------------------------------------------
  // RESPONSE HANDLING
  //------------------------------------------------------------------------------------------

  /**
	 * Service identifier.
	 *
	 * @type {{VOTING: number, CLIENT: number, DISCOVERY: number}}
	 */
  calls.service = {
    CLIENT: 0,
    VOTING: 1,
    DISCOVERY: 2,
  };

  /**
	 * Creates a universal response for every call in the voting library. Check the
	 * README in the examples folder to understand the response structure.
	 *
	 * @param {Object/String/null} message - Message to be presented to the user with
	 * the response or explaining the state of an operation
	 * @param {Object/null} error - Error to be handled (types: string, axios (voting,
	 * discovery), other)
	 * @returns {Object} Response to be output by the library
	 */
  response.build = function(message, error) {
    // response variable
    let response = {};
    let service;

    // set code
    if (error === null)
      response.code = 200;

    // parse and set error (types: string, axios (voting, discovery), other)
    try {
      if (error !== null) {
        if (typeof error === 'object' && 'data' in error && 'service' in error) {
          // set service and error
          service = error.service;
          error = error.data;

          // parse error by type
          switch (typeof error) {
          case 'string':
            // parse error data (string)
            response.error = error;
            break;
          case 'object':
            // parse error data (axios)
            if ('response' in error && error.response !== undefined) {
              // set code and error (voting, discovery)
              response.code = error.response.status;
              switch (service) {
              case calls.service.DISCOVERY:
                response.error = error.response.data.message;
                break;
              case calls.service.VOTING:
                if (message === null) message = error.response.data.message;
                response.error = error.response.data.error;
                break;
              default:
                response.error = error.response.statusText;
                break;
              }
            } else if ('code' in error && error.code === 'ECONNREFUSED') {
              response.error = 'Could not connect to server';
            } else {
              response.error = error;
            }
            break;
          default:
            response.error = error;
            break;
          }
        } else {
          response.error = error;
        }
      }
    } catch (e) {
      console.log(e);
    }

    // set message
    if (message === null && error !== null) {
      switch (service) {
      case calls.service.CLIENT:
        response.message = 'Error: Client Library';
        break;
      case calls.service.VOTING:
        response.message = 'Error: Voting Server';
        break;
      case calls.service.DISCOVERY:
        response.message = 'Error: Discovery Service';
        break;
      default:
        response.message = 'Error';
        break;
      }
    } else if (message !== null) {
      response.message = message;
    }

    return response;
  };

  /**
	 * Verifies the validity of signature appended to a response.
	 *
	 * @param {String} certificate - Certificate of the signer
	 * @param {Object} response - Response provided by the axios library
	 * @return {boolean} Boolean to assess if signature is valid
	 */
  response.verify = function(certificate, response) {
    // get key and signature
    let key = crypto.rsa.keyFromCertificate(certificate);
    let signature = response.headers['x-signature'];

    // verify signature
    return crypto.rsa.verify(response.data, signature, key);
  };

  /**
	 * Universal error generator for the voting client library.
	 *
	 * @param {String/Object} error - Error to be handled
	 * @param {number} service - Service that caused the error
	 * @returns {{data: Object, service: number}} Error
	 */
  response.setError = function(error, service) {
    // return error if already built
    if (typeof error === 'object' && 'data' in error && 'service' in error)
      return error;

    return {
      'data': error,
      'service': service,
    };
  };

  //------------------------------------------------------------------------------------------

  // add methods to be exported
  calls.node = node;
  calls.discovery = discovery;
  calls.users = users;
  calls.db = db;
  calls.response = response;

  return calls;
})();

module.exports = services;
