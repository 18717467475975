import { db } from './lib';

export default class Storage {

  constructor() {
    //
  }

  static set(key, value ) {
    return new Promise((resolve, reject) => {
      db.save(key, value).then(() => resolve(true)).catch((reason) => reject(reason));
    });
  }

  static get(key) {
    return new Promise((resolve, reject) => {
      db.get(key).then((value) => resolve(value)).catch((reason) => reject(reason));
    });
  }
  static clear(...keys) {
    return new Promise((resolve, reject) => {
      db.clear(...keys).then((response) => resolve(response)).catch((reason) => reject(reason));
    });
  }

}

export { Storage };
