import React from 'react';
import SplashImg from '../../../images/splash.png';

export default class Splash extends React.PureComponent {

  render() {
    return (<div style={{ display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', justifySelf: 'center', flex: 1, flexDirection: 'column' }}>
      <img id='splash-img' alt='splash' style={{ width: 320, height: 'auto', display: 'block', objectFit: 'cover', margin: 'auto' }} src={SplashImg} />
    </div>);
  }

}
