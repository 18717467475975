import { Map as ImmutableMap, fromJS } from 'immutable';
import {
  EUNOMIA_CASCADE_IMPORT,
  EUNOMIA_CASCADE_POST_UPDATE,
} from '../constants';

const initialState = ImmutableMap();

const importCascade = (state, payload) => {
  const { cascadeId, posts } = payload;
  if (cascadeId && posts) {
    // console.log(posts.length);
    return state.set(cascadeId, fromJS(posts));
  }
  return state;
};

const updateCascadePost = (state, post) => {
  if (post.source_id && post.source_url && post.cascade_id) {
    const existing = state.get(post.cascade_id, null);
    if (existing) {
      const existingJson = existing.toJS();
      existingJson.forEach((entry, index) => {
        if (entry.source_id && entry.source_url && entry.source_id === post.source_id && entry.source_url === post.source_url) {
          existingJson[index] = post;
        }
      });
      return state.set(post.cascade_id, fromJS(existingJson));
    }
  }
  return state;
};

export default function eunomiaCascades(state = initialState, action) {
  if (action.type === EUNOMIA_CASCADE_IMPORT){
    return importCascade(state, action.payload);
  }
  if (action.type === EUNOMIA_CASCADE_POST_UPDATE && action.post) {
    return updateCascadePost(state, action.post);
  }
  return state;
}
