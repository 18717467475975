import React from 'react';
import euLogo from '../../../../images/europe.jpg';

export function terms () {
  return  (
    <div className={'rich-formatting terms-modal-content'}>
      <h2>Consent for Research and Privacy Policy</h2>
      <div style={{ marginBottom: 10 }}>
        <h3>Information on the project</h3>
      </div>
      <div style={{ marginBottom: 10 }}>
        <p style={{ marginBottom: 10 }}>
          <a href='https://eunomia.social' target='_blank' rel='nofollow noopener noreferrer'>EUNOMIA</a>
          {' '}aims to raise critical awareness of social media and explore technologies that may help
          {' '}protect users against misinformation in a transparent and decentralized manner. In this
          {' '}context, it builds upon Mastodon to offer a platform that promotes "trust" over "likes"
          {' '}by providing additional features that support users’ assessment of the trustworthiness
          {' '}of posts. These features refer primarily to identifying whether the content described
          {' '}in a post has been posted before and in what context, in addition to AI-based information
          {' '}on sentiment and subjectivity, as well as existing information such as the age of the
          {' '}account or its number of connections and posts.
        </p>
        <p style={{ marginBottom: 10 }}>
          To participate in this project,you need to sign up on EUNOMIA. Please post in the same
          {' '}way as you would normally do in your personal social media (i.e., post, comment, share).
        </p>
        <p>
          EUNOMIA unites a highly complementary consortium of 10 partners from 9 European countries
          from cross-disciplines and cross-sectors including academic, decentralised social media,
          public journalism organisations and SMEs (University of Greenwich, Blasting News,
          Trilateral Research, SYNYO, INOV, University of West Attica, University of Nicosia, ORF,
          Eugen Rochko and SIMAVI).
        </p>
      </div>
      <h3>Participation in the Project</h3>
      <div>
        <p style={{ marginBottom: 10 }}>
          When signing-up, you are consenting to participate in the Project. Participation in the Project
          is strictly voluntary, and you may revoke your consent and delete your account at any time.
        </p>
      </div>
      <h3>Data Controllers</h3>
      <div>
        <p style={{ marginBottom: 10 }}>
          The Data Controller for your data is <strong>SOFTWARE IMAGINATION & VISION SRL</strong>.
          incorporated in Sos. Bucharest – Ploiești, nr. 73 – 81,
          Complex Victoria Park, Corp C4, parter, zona B, Sector 1, Bucharest, Romania <strong>(SIMAVI)</strong>.
        </p>
      </div>
      <h3>What information and personal data do we collect?</h3>
      <div>
        <p style={{ marginBottom: 10 }}>
          <span style={{ textDecoration: 'underline' }}>Basic account information:</span> If you
          register on this server, you are asked to enter a username, an e-mail address, and a
          password. You may also enter additional profile information such as a display name and
          biography and upload a profile picture and header image. The username, display name,
          biography, profile picture, and header image are always listed publicly.
        </p>
        <p style={{ marginBottom: 10 }}>
          <span style={{ textDecoration: 'underline' }}>Posts, following and other public information:</span> The list of people you follow is listed publicly, the same is true for your followers. When you submit a message, the date and time is stored as well as the application you submitted the message from. Messages may contain media attachments, such as pictures and videos. Public and unlisted posts are available publicly. When you feature a post on your profile, that is also publicly available information. Your posts are delivered to your followers, in some cases it means they are delivered to different servers and copies are stored there. When you delete posts, this is likewise delivered to your followers. The action of reblogging or favouriting another post is always public.
        </p>
        <p style={{ marginBottom: 10 }}>
          <span style={{ textDecoration: 'underline' }}>Direct and followers-only posts:</span>  All posts are stored and processed on the EUNOMIA server of the instance where you signed up. Followers-only posts are delivered to your followers and users who are mentioned in them, and direct posts are delivered only to users mentioned in them. If the particular instance where you signed up is federated with other intances, this means that they may be delivered to their servers too, and copies are stored there.
        </p>
        <p style={{ marginBottom: 10 }}>
          <span style={{ textDecoration: 'underline' }}>IPs and other metadata:</span> When you log in, we record the IP address you log in from, as well as the name of your browser application. All the logged in sessions are available for your review and revocation in the settings. The latest IP address used is stored for up to 12 months. We also may retain server logs which include the IP address of every request to our server. Additionally, participants’ trustworthiness votes will be collected to be anonymously displayed to the users.
        </p>
      </div>
      <div style={{ marginBottom: 10, marginTop: 20 }}>
        <h3>Legal basis and purpose</h3>
      </div>
      <div>
        <p style={{ marginBottom: 10 }}>
          We rely on your consent as the lawful basis for processing your data. Any of the information and personal data we collect from you may be used to provide the core functionalities of EUNOMIA toolkit.
        </p>
        <p style={{ marginBottom: 10 }}>
          Note, the administrators of EUNOMIA instances reserves the right to remove a post/ toot and ultimately delete an account, after giving suitable warnings, when there are abusive or inappropriate posts/ toots.
        </p>
      </div>
      <div style={{ marginBottom: 10, marginTop: 20 }}>
        <h3>How do we protect your information?</h3>
      </div>
      <div>
        <p style={{ marginBottom: 10 }}>
          We recognize the importance to protect and secure the users' personal data and we are committed to adopt and implement all appropriate technical and organizational measures for the security and protection of the users' personal data. It is necessary to process such data for carrying out the project in a proper and effective manner.
        </p>
        <p style={{ marginBottom: 10 }}>
          We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. Among other things, your browser session, as well as the traffic between your applications and the API, are secured with SSL, and your password is hashed using a strong one-way algorithm. You may enable two-factor authentication to further secure access to your account.
        </p>
        <p style={{ marginBottom: 10 }}>
          We will pseudonymise and where possible anonymise the data you provide us to the extent possible when and if they will be used, removing all personally identifiable information where appropriate. We will only collect and process data that is strictly necessary for running the project.
        </p>
      </div>
      <div style={{ marginBottom: 10, marginTop: 20 }}>
        <h3>Do we transfer your data outside the EU? </h3>
        <div>
          <p style={{ marginBottom: 10 }}>
            No, EUNOMIA does not transfer your data to third-countries outside the EU.
          </p>
        </div>
      </div>
      <div style={{ marginBottom: 10, marginTop: 20 }}>
        <h3>What is our data retention policy?</h3>
      </div>
      <div>
        <p style={{ marginBottom: 10 }}>
          We will make a good faith effort to:
        </p>
        <div style={{ marginBottom: 10 }}>
          <ul>
            <li>
              Retain server logs containing the IP address of all requests to this server, in so far as such logs are kept, no more than 90 days.
            </li>
            <li>
              Retain the IP addresses associated with registered users no more than 12 months.
            </li>
          </ul>
        </div>
        <p style={{ marginBottom: 10 }}>
          You can request and download an archive of your content, including your posts, media attachments, profile picture, and header image.
        </p>
        <p style={{ marginBottom: 10 }}>
          You may irreversibly delete your account at any time. Upon deletion of your account all your posts/ toots will also be deleted from all EUNOMIA nodes.
        </p>
      </div>
      <div style={{ marginBottom: 10, marginTop: 20 }}>
        <h3>Do we use cookies?</h3>
      </div>
      <div>
        Yes, we only use essential cookies. Cookies are small files dropped in your device through your Web browser. These cookies enable the site to recognize your browser and, if you have a registered account, associate it with your registered account.
      </div>
      <div style={{ marginBottom: 10, marginTop: 20 }}>
        <h3>Your rights:</h3>
      </div>
      <p style={{ marginBottom: 10 }}>
        You have the following rights in relation to any of your personal data that we process. You can exercise your rights, explained below, by contacting by phone, in writing or emailing us at: compliance@greenwich.ac.uk.
      </p>
      <div style={{ marginBottom: 10 }}>
        <ul>
          <li>
            Right to withdraw consent– You can withdraw your consent that you have previously given to one or more specified purposes to process your personal data. This will not affect the lawfulness of any processing carried out before you withdraw your consent. It may mean we are not able to provide certain products or services to you and we will advise you if this is the case.
          </li>
          <li>
            Right of access– You can ask us to verify whether we are processing personal data about you, and if so, to have access to a copy of such data.
          </li>
          <li>
            Right to rectification and erasure– You can ask us to correct our records if you believe they contain incorrect or incomplete information about you or ask us to erase your personal data after you withdraw your consent to processing or when we no longer need it for the purpose it was originally collected.
          </li>
          <li>
            Right to restriction of processing– You can ask us to temporarily restrict our processing of your personal data if you contest the accuracy of your personal data, prefer to restrict its use rather than having us erase it, or need us to preserve it for you to establish, exercise, or defend a legal claim. A temporary restriction may apply while verifying whether we have overriding legitimate grounds to process it. You can ask us to inform you before we lift that temporary processing restriction.
          </li>
          <li>
            Right to data portability– In some circumstances, where you have provided personal data to us, you can ask us to transmit that personal data (in a structured, commonly used, and machine-readable format) directly to another company.
          </li>
          <li>
            Right to object – You can object to our use of your personal data for direct marketing purposes, including profiling or where processing has taken the form of automated decision making. However, we may need to keep some minimal information (e.g., email address) to comply with your request to cease marketing to you.
          </li>
          <li>
            Right to make a complaint to the <a href='https://ico.org.uk/concerns/handling/' target='_blank' rel='nofollow noopener noreferrer'>UK Information Commissioner’s Office</a> regarding any concerns you may have about our data handling practices.
          </li>
        </ul>
      </div>
      <p style={{ marginBottom: 10 }}>
        If you have any questions about this research or your prospective involvement in it, please contact:
      </p>
      <div style={{ marginBottom: 10 }}>
        <table>
          <thead>
            <tr>
              <td>
                Project coordinator
              </td>
              <td>
                Data Protection Officer
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Dr. George Loukas,<br />
                University of Greenwich<br />
                E-mail: G.Loukas@greenwich.ac.uk
              </td>
              <td>
                Peter Garrod,<br />
                University of Greenwich<br />
                E-mail: compliance@greenwich.ac.uk
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        className='container'
        style={{ color: '#737d99', display: 'flex', alignItems: 'center', marginBottom: 10 }}
      >
        <img src={euLogo} style={{ maxHeight: 40, marginRight: 10 }} alt='EU Logo' />
        <p>EUNOMIA project has received funding from the European Union’s H2020 research and innovation programme under
          the grant agreement No 825171</p>
      </div>
    </div>
  );
}
